import BottomNav from "../BottomNav.jsx";

export default function RedirectModal({ handleCancel, handleContinue }) {
  return (
    <>
      <h1 className="heading">Heads Up!</h1>
      <p>
        You are about to leave this page, any information you have provided will
        not be saved.
      </p>
      <BottomNav
        continueLabel="Continue"
        cancelLabel="Cancel"
        handleCancel={handleCancel}
        handleContinue={handleContinue}
        enableContinue={true}
      />
    </>
  );
}
