import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import DatePicker from "../../components/DatePicker.jsx";
import {
  getAvailableSlots,
  setDateTimeShowCalendar,
  setCurrentlyOpenDateDropdown,
} from "../../store/scheduler.js";
import CalendarPicker from "react-datepicker";
import { useEffect, useMemo } from "react";
import { ReactComponent as IconWeek } from "../../assets/icons/icons-week.svg";
import TagManager from "react-gtm-module";

import "./CalendarView.css";

// TODO: Add year to month title

export default function CalendarView() {
  const dispatch = useDispatch();
  const { franchiseSlots } = useSelector((state) => state.scheduler);
  const currentlyOpenDateDropdown = useSelector(
    (state) => state.scheduler.currentlyOpenDateDropdown
  );
  const selected = currentlyOpenDateDropdown
    ? dayjs(currentlyOpenDateDropdown).toDate()
    : dayjs().toDate();

  const handleGADatepickerEvent = () => {
    const dataLayer = {
      event: "event_week_view_clicked",
    };

    TagManager.dataLayer({
      dataLayer,
    });
  };

  const changeView = (e) => {
    e.preventDefault();
    handleGADatepickerEvent();
    dispatch(setDateTimeShowCalendar(false));
  };

  const getFranchiseSlot = useMemo(() => {
    const key = Object.keys(franchiseSlots)[0];
    return { ...franchiseSlots[key], key };
  }, [franchiseSlots]);

  // User selected date on calendar
  const getNewDate = (date) => {
    const newDate = dayjs(date);
    const dateKey = newDate.format("YYYY-MM-DD");

    // Pull new date from API
    dispatch(getAvailableSlots(newDate, newDate));

    // Open up our new dropdown date
    dispatch(setCurrentlyOpenDateDropdown(dateKey));
  };

  // Determines whether or not previous button should appear
  const showPrevButton = () => {
    const todayFormatted = dayjs().format("YYYY-MM-DD");
    const selectedFormatted = dayjs(currentlyOpenDateDropdown).format(
      "YYYY-MM-DD"
    );

    return selectedFormatted && todayFormatted !== selectedFormatted;
  };

  const handlePrevClicked = (e) => {
    e.preventDefault();
    const currentDate = dayjs(currentlyOpenDateDropdown);
    const prevDate = currentDate.subtract(1, "day");

    getNewDate(prevDate);
  };

  const handleNextClicked = (e) => {
    e.preventDefault();
    const currentDate = dayjs(currentlyOpenDateDropdown);
    const nextDate = currentDate.add(1, "day");

    getNewDate(nextDate);
  };

  //* Component Did Mount
  useEffect(() => {
    // Dispatch to get all times
    dispatch(getAvailableSlots(selected, selected));

    if (!currentlyOpenDateDropdown) {
      const newDate = dayjs().format("YYYY-MM-DD");
      // Open up our new dropdown date
      dispatch(setCurrentlyOpenDateDropdown(newDate));
    }
  }, []);

  return (
    <div className="calendarview">
      <button className="datetime-button-link" onClick={changeView}>
        <IconWeek />
        Week view
      </button>
      <div className="calendarview-container">
        <div>
          <CalendarPicker
            selected={selected}
            onChange={getNewDate}
            inline
            dateFormatCalendar={"LLLL yyyy"}
            minDate={dayjs().toDate()}
          />
        </div>
        <div className="calendarview-datepicker">
          <DatePicker data={getFranchiseSlot} alwaysOpen />
          <div className="calendarview-navbuttons">
            {showPrevButton() && (
              <button onClick={handlePrevClicked}>Prev day</button>
            )}
            <button
              onClick={handleNextClicked}
              className="calendarview-nextday"
            >
              Next day
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
