import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dayjs from "dayjs";
import TimeRanOutModal from "../components/modals/TimeRanOutModal";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const initialState = {
  // franchise: {},
  franchise: {
    locationId: "",
    shopId: null,
    franchiseName: "",
    street: "",
    city: "",
    state: "",
    zip: null,
    latitude: "",
    longitude: "",
    phone: "",
    schedulerLive: null,
    franchiseeEmail: "",
    storeEmail: "",
    openingTime: "",
    closingTime: "",
    breakStartTime: "",
    breakEndTime: "",
    slotDurationMins: null,
    workingDays: "",
    urlSlug: "",
    timeZone: "",
    isAfterHoursDropoff: true,
    services: [],
    socialLinks: {},
  },
  franchiseSlots: {},
  currentStep: 1,
  dateTimeShowCalendar: false,
  currentlyOpenDateDropdown: "",
  selectedSlot: {
    date: "",
    time: "",
  },
  personalInfo: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    altPhone: "",
    firstVisit: "empty",
    zip: "",
    scorpionSessionId: "",
    canCall: false,
    canEmail: false,
    canText: false,
  },
  temporarySlotId: "",
  selectedServices: [],
  otherText: "",
  isDropoff: false,
  isAfterHoursDropoff: false,
  selectedShuttleService: false,
  vehicleYears: [],
  vehicleMakes: [],
  vehicleModels: [],
  vehicleInfo: {
    year: "",
    make: "",
    model: "",
    color: "",
  },
  validFields: {
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    firstVisit: false,
    zip: false,
  },
  displayedErrors: {
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    firstVisit: false,
  },
  modalContents: "",
  confirmationNumber: 0,
  emailConfirmation: false,
  cancellationSuccess: false,
  redirectError: false,
  mktCookies: [],
  coupons: [],
  dgbId: null,
  dgbCustomerData: {},
  dgbVehicleId: null,
  recommendedServices: [],
  isVehicleNew: false,
  carePlusReferrer: null,
  dgbReferrer: null,
};

// Slice
const slice = createSlice({
  name: "scheduler",
  initialState: initialState,
  reducers: {
    getFranchiseSuccess: (state, action) => {
      // const phoneParts = action.payload.res.data.data.phone &&
      //     action.payload.res.data.data.phone.match(/^\(?(\d{3})\D*(\d{3})\D*(\d{4})$/);
      // const formattedPhone = '('+phoneParts[1]+') '+phoneParts[2]+'-'+phoneParts[3];

      // state.franchise = {...action.payload.res.data.data, phone: formattedPhone};

      // state.franchise = action.payload.data.data;
      state.franchise = action.payload.res.data.data;
      // state.franchise = data.data.data;
      // state.franchise.phone = formattedPhone;
    },
    setDgbId: (state, action) => {
      state.dgbId = action.payload;
    },
    setDgbApiTokenSuccess: (state, action) => {
      cookies.set("cdat-t-st", action.payload.res.data.token, {
        maxAge: 82800,
      });
    },
    setDgbCustomerSuccess: (state, action) => {
      // state.dgbCustomerData = action.payload.res.data;
      state.dgbCustomerData = action.payload;
    },
    setDgbVehicleId: (state, action) => {
      state.dgbVehicleId = action.payload;
    },
    setRecommendedServices: (state, action) => {
      state.recommendedServices = action.payload;
    },
    setIsVehicleNew: (state, action) => {
      state.isVehicleNew = action.payload;
    },
    setCarePlusReferrer: (state, action) => {
      state.carePlusReferrer = action.payload;
    },
    setDgbReferrer: (state, action) => {
      state.dgbReferrer = action.payload;
    },
    getAvailableSlotsSuccess: (state, action) => {
      // console.log("action.payload slots succ", action.payload);
      state.franchiseSlots = { ...action.payload.res.data.data };
      // state.franchiseSlots = {...action.payload.data.data};
    },
    bookAppointmentSuccess: (state, action) => {
      // Save our confirmation ID
      // state.confirmationNumber = action.payload.res.data.data.bookingId;
      state.confirmationNumber = action.payload.res.data.appointmentId;
    },
    confirmAppointmentSuccess: (state, action) => {
      state.emailConfirmation = action.payload.res.data.response;
    },
    getCancellationSuccess: (state, action) => {
      // state.cancellationSuccess = true;
      state.cancellationSuccess = action.payload;
    },
    getYearsSuccess: (state, action) => {
      state.vehicleYears = action.payload.res.data.data;
    },
    getMakesByYearSuccess: (state, action) => {
      state.vehicleMakes = action.payload.res.data.data;
    },
    getModelsSuccess: (state, action) => {
      state.vehicleModels = action.payload.res.data.data;
    },
    clearVehicleMakes: (state, action) => {
      state.vehicleMakes = [];
    },
    clearVehicleModels: (state, action) => {
      state.vehicleModels = [];
    },
    blockSlotTemporarilySuccess: (state, action) => {
      state.temporarySlotId = action.payload.res.data.data.slotId;
    },
    releaseSlotSuccess: (state, action) => {
      state.temporarySlotId = "";
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setCurrentlyOpenDateDropdown: (state, action) => {
      state.currentlyOpenDateDropdown = action.payload;
    },
    setIsAfterHoursDropoff: (state, action) => {
      state.isAfterHoursDropoff = action.payload;
    },
    setPersonalInfo: (state, action) => {
      state.personalInfo = { ...state.personalInfo, ...action.payload };
    },
    setDateTimeShowCalendar: (state, action) => {
      state.dateTimeShowCalendar = action.payload;
    },
    setSelectedServices: (state, action) => {
      state.selectedServices = action.payload;
    },
    setVehicleInfo: (state, action) => {
      // console.log("Set Vehicle Info", action.payload);
      state.vehicleInfo = { ...state.vehicleInfo, ...action.payload };
    },
    setValidFields: (state, action) => {
      state.validFields = { ...state.validFields, ...action.payload };
    },
    setDisplayedErrors: (state, action) => {
      state.displayedErrors = { ...state.displayedErrors, ...action.payload };
    },
    selectTimeSlot: (state, action) => {
      // This selects a time slot on the date-time view. It does not block a slot
      state.selectedSlot = { ...state.selectedSlot, ...action.payload };
    },
    setSelectedShuttleService: (state, action) => {
      state.selectedShuttleService = action.payload;
    },
    setModalContents: (state, action) => {
      state.modalContents = action.payload;
    },
    setOtherText: (state, action) => {
      state.otherText = action.payload;
    },
    setIsDropoff: (state, action) => {
      state.isDropoff = action.payload;
    },
    clearSession: (state, action) => {
      state = initialState;
    },
    setRedirectError: (state, action) => {
      state.redirectError = action.payload;
    },
    setMarketingCookies: (state, action) => {
      state.mktCookies = action.payload;
    },
    setCoupons: (state, action) => {
      state.coupons = action.payload;
    },
  },
});

export default slice.reducer;

// Actions
export const {
  getFranchiseSuccess,
  setDgbId,
  setDgbApiTokenSuccess,
  setDgbVehicleId,
  setRecommendedServices,
  setIsVehicleNew,
  setCarePlusReferrer,
  setDgbReferrer,
  setDgbCustomerSuccess,
  setCurrentStep,
  setDateTimeShowCalendar,
  getAvailableSlotsSuccess,
  selectTimeSlot,
  setCurrentlyOpenDateDropdown,
  setIsAfterHoursDropoff,
  blockSlotTemporarilySuccess,
  setSelectedServices,
  releaseSlotSuccess,
  getYearsSuccess,
  setVehicleInfo,
  getMakesByYearSuccess,
  getModelsSuccess,
  clearVehicleMakes,
  clearVehicleModels,
  setPersonalInfo,
  setValidFields,
  setDisplayedErrors,
  setSelectedShuttleService,
  setModalContents,
  clearSession,
  bookAppointmentSuccess,
  confirmAppointmentSuccess,
  setOtherText,
  setIsDropoff,
  getCancellationSuccess,
  setRedirectError,
  setMarketingCookies,
  setCoupons,
} = slice.actions;

//=======================================================
// Async Thunks
//=======================================================

const baseUrl =
  "https://mc-f7881bde-c03a-46d5-a923-389807-cd.azurewebsites.net";
const cbaBaseAzureUrl = process.env.REACT_APP_BACKEND_URL;
const oauthUrl = process.env.REACT_APP_OAUTH_URL;
const dgbApiUrl = process.env.REACT_APP_DGB_API_URL;
// const cbaBaseAzureUrl = "http://localhost:3002";

const getServices = (selectedServices, franchise) => {
  // Map our index to our service ID
  return selectedServices.map((service) => {
    return franchise.services[service].serviceId;
  });
};

// Get Franchise Actions
export const getFranchiseByName = (franchiseName) => async (dispatch) => {
  try {
    // const res = await axios.get(`${baseUrl}/api/v1/sitecorecontent/GetFranchiseeDetailsByFranchiseeName?franchiseeName=${franchiseName}`)
    const res = await axios.get(
      `${cbaBaseAzureUrl}/getFranchiseDetailsByFranchiseName?franchiseName=${franchiseName}`
    );

    // console.log({ res });

    if (res.status === 200) {
      dispatch(getFranchiseSuccess({ res }));
    } else {
      // Handle failed status codes
      throw { message: res.data.data };
    }
  } catch (e) {
    dispatch(setRedirectError(true));
    return console.error(e.message);
  }
};

// Get DGB Token
export const getDgbToken = () => async (dispatch) => {
  try {
    const res = await axios.post(`${oauthUrl}/auth`, {
      client_id: process.env.REACT_APP_CBA_API_CLIENT_ID,
      client_secret: process.env.REACT_APP_CBA_API_CLIENT_SECRET,
      is_admin: true,
    });

    if (res.status === 200) {
      return res;
      // dispatch(setDgbApiTokenSuccess({ res }));
    } else {
      // Handle failed status codes
      throw { message: res.data.data };
    }
  } catch (e) {
    dispatch(setRedirectError(true));
    return console.error(e.message);
  }
};

// Get DGB Object
export const getDgbCustomerById = (dgbId) => async (dispatch) => {
  try {
    const apiToken = cookies.get("cdat-s-st");
    const res = await axios.get(`${dgbApiUrl}/v2/customer/byID`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        "Content-Type": "application/json",
      },
      params: {
        dgbId: dgbId,
        // dgbId: "e14e7f5b-b680-45a9-a719-f9dc7ff2demo",
      },
    });

    // console.log({ res });

    if (res.status === 200) {
      const transformedResponse = {
        ...res.data,
        garage: Object.keys(res.data.garage).reduce((array, key) => {
          return [
            ...array,
            {
              ...res.data.garage[key],
              garageId: key,
            },
          ];
        }, []),
      };
      // dispatch(setDgbCustomerSuccess({ res }));
      dispatch(setDgbCustomerSuccess(transformedResponse));
    } else {
      // Handle failed status codes
      throw { message: res.data.data };
    }
  } catch (e) {
    dispatch(setRedirectError(true));
    return console.error(e.message);
  }
};

export const getDgbCustomer = (dgbId) => async (dispatch) => {
  const apiToken = cookies.get("cdat-s-st");
  if (apiToken) {
    await dispatch(getDgbCustomerById(dgbId));
  } else {
    await dispatch(getDgbToken()).then((response) => {
      if (response.status === 200) {
        cookies.set("cdat-s-st", response.data.token, { maxAge: 82800 });
        dispatch(getDgbCustomerById(dgbId));
      }
    });
  }
};

// Get vehicle years
export const getVehicleYears = () => async (dispatch) => {
  try {
    // const res = await axios.get(`${baseUrl}/api/v1/carMakeModel/getYears`);
    const res = await axios.get(`${cbaBaseAzureUrl}/getYears`);

    // console.log({ res });

    // if(res.data.statusCode === 200){
    if (res.status === 200) {
      dispatch(getYearsSuccess({ res }));
    } else {
      // Handle failed status codes
      throw { message: res.data.data };
    }
  } catch (e) {
    dispatch(setRedirectError(true));
    return console.error(e.message);
  }
};

// Get vehicle makes
export const getVehicleMakesByYear = (year) => async (dispatch) => {
  try {
    // const res = await axios.get(`${baseUrl}/api/v1/carMakeModel/getMakesByYear?year=${year}`)
    const res = await axios.get(
      `${cbaBaseAzureUrl}/getMakesByYear?year=${year}`
    );

    // console.log(res.data.data);
    // console.log({ res });

    // if(res.data.statusCode === 200){
    if (res.status === 200) {
      dispatch(getMakesByYearSuccess({ res }));
    } else {
      // Handle failed status codes
      throw { message: res.data.data };
    }
  } catch (e) {
    dispatch(setRedirectError(true));
    return console.error(e.message);
  }
};

// Get vehicle models
export const getVehicleModelsByYearAndMake =
  () => async (dispatch, getState) => {
    try {
      const state = getState();
      const { year, make } = state.scheduler.vehicleInfo;
      // const res = await axios.get(`${baseUrl}/api/v1/carMakeModel/getModelsByYearAndMake?year=${year}&make=${make}`)
      const res = await axios.get(
        `${cbaBaseAzureUrl}/getModelsByYearAndMake?year=${year}&make=${make}`
      );

      // if(res.data.statusCode === 200){
      if (res.status === 200) {
        dispatch(getModelsSuccess({ res }));
      } else {
        // Handle failed status codes
        throw { message: res.data.data };
      }
    } catch (e) {
      dispatch(setRedirectError(true));
      return console.error(e.message);
    }
  };

// Block slot temporarily
export const blockSlotTemporarily = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { selectedServices, franchise } = state.scheduler;

    // Service IDs need to be passed in to block slot
    const services = getServices(selectedServices, franchise);

    const body = {
      franchiseeId: state.scheduler.franchise.franchiseeId,
      date: state.scheduler.selectedSlot.date,
      slot: state.scheduler.selectedSlot.time,
      serviceIds: services,
    };

    const res = await axios.post(
      `${baseUrl}/api/v1/slotBooking/blockSlotTemporarily`,
      body
    );

    if (res.data.statusCode === 200) {
      dispatch(blockSlotTemporarilySuccess({ res }));
    } else {
      // Handle failed status codes
      throw { message: res.data.data };
    }
  } catch (e) {
    dispatch(setRedirectError(true));
    return console.error(e.message);
  }
};

// Unblock Temporary Slot
export const releaseTemporarySlot = () => async (dispatch, getState) => {
  try {
    const state = getState();

    const body = {
      slotId: state.scheduler.temporarySlotId,
    };

    const res = await axios.post(
      `${baseUrl}/api/v1/slotBooking/releaseTemporarilyBlockedSlot`,
      body
    );

    if (res.data.statusCode === 200) {
      dispatch(releaseSlotSuccess({ res }));
    } else {
      // Handle failed status codes
      throw { message: res.data.data };
    }
  } catch (e) {
    // dispatch(setRedirectError(true));
    return console.error(e.message);
  }
};

// Get all available franchise slots
export const getAvailableSlots =
  (startDate, endDate = null) =>
  async (dispatch, getState) => {
    try {
      const state = getState();

      const { selectedServices, franchise } = state.scheduler;
      // console.log("getAvailableSlots", "ss", selectedServices, franchise)

      // If we pass in a start date, pull that date, otherwise default to today
      const currentDate = startDate ? dayjs(startDate) : dayjs();
      const finalDate = endDate ? dayjs(endDate) : currentDate.add(6, "day");

      const today = currentDate.format("YYYY-MM-DD");
      const end = finalDate.format("YYYY-MM-DD");

      const services = getServices(selectedServices, franchise);

      const body = {
        // franchiseeId: state.scheduler.franchise.franchiseeId,
        // shopId: state.scheduler.franchise.shopId,
        // shopId: 211,
        shopId: process.env.REACT_APP_ENV === "DEV" ? 211 : franchise.shopId,
        uin: franchise.locationId,
        startDate: today,
        endDate: end,
        // dates: [today, end],
        isWaiter: !(state.scheduler.isDropoff === "Yes"),
        // isLimitedSlotsApplicable: state.scheduler.isDropoff === 'No',
        serviceIds: services,
      };

      // const res = await axios.post(`${baseUrl}/api/v1/slotBooking/getAvailableSlots`, body)
      const res = await axios.post(
        `${cbaBaseAzureUrl}/getAvailableSlots`,
        body
      );

      // if(res.data.statusCode === 200){
      if (res.status === 200) {
        dispatch(getAvailableSlotsSuccess({ res }));
      } else {
        // Handle failed status codes
        throw { message: res.data.data };
      }
    } catch (e) {
      dispatch(setRedirectError(true));
      return console.error(e.message);
    }
  };

// /bookAppointment

// Book appointment
export const oldbookAppointment = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      personalInfo,
      vehicleInfo,
      selectedServices,
      franchise,
      selectedShuttleService,
      otherText,
      temporarySlotId,
      selectedSlot,
      isDropoff,
      isAfterHoursDropoff,
    } = state.scheduler;

    // Opt user in to all contact preferences
    const contactPreferences = ["Phone", "Text", "Email"];

    // Map our index to our service ID
    const services = getServices(selectedServices, franchise);

    const body = {
      appointmentDetails: {
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        email: personalInfo.email,
        phone: personalInfo.phone,
        zipCode: personalInfo.zip,
        scorpionSessionId: personalInfo.scorpionSessionId,
        contactPreferences: contactPreferences,
        address: "",
        VIN: "",
        make: vehicleInfo.make,
        model: vehicleInfo.model,
        year: vehicleInfo.year,
        color: vehicleInfo.color,
        services: services,
        isAfterHoursDropoff,
        otherServices: [],
        shuttleServiceSelected: isDropoff === true && selectedShuttleService,
        notes: otherText,
        alternatePhone: personalInfo.altPhone,
        isFirstTimeVisiting: personalInfo.firstVisit === "Yes" ? true : false,
      },
      franchiseeId: franchise.franchiseeId,
      userId: "",
      slotId: temporarySlotId,
      date: selectedSlot.dropoffDate
        ? selectedSlot.dropoffDate
        : selectedSlot.date,
      slot: selectedSlot.time,
    };

    const res = await axios.post(
      `${baseUrl}/api/v1/slotBooking/bookAppointment`,
      body
    );

    //! They aren't camelCasing StatusCode
    if (res.data.status === 200) {
      dispatch(bookAppointmentSuccess({ res }));
    } else {
      // Handle failed status codes
      throw { message: res.data.data };
    }
  } catch (e) {
    // Handle booking error when times are not available
    if (e.message === "Sorry! The selected slot is not available now.") {
      const timeModal = <TimeRanOutModal />;
      dispatch(setModalContents(timeModal));
      return console.error(e.message);
    }

    dispatch(setRedirectError(true));
    return console.error(e.message);
  }
};

// CBA book appointment
export const bookAppointment = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      personalInfo,
      vehicleInfo,
      selectedServices,
      franchise,
      selectedShuttleService,
      otherText,
      temporarySlotId,
      selectedSlot,
      isDropoff,
      isAfterHoursDropoff,
      mktCookies,
      coupons,
      recommendedServices,
      dgbCustomerData,
      dgbVehicleId,
    } = state.scheduler;

    // Opt user in to all contact preferences
    const contactPreferences = ["Phone", "Text", "Email"];

    // Map our index to our service ID
    const services = getServices(selectedServices, franchise);

    const servicesFormatted = selectedServices.map((service, i) => {
      return (
        `${franchise.services[service].text}` +
        `${i < selectedServices.length - 1 ? "\n" : ""}`
      );
    });

    const recommendedServicesFormatted = recommendedServices.map((id) => {
      const name = dgbCustomerData.garage
        ?.find((vehicle) => vehicle.garageId === dgbVehicleId)
        .recommendedHistory[0]?.jobs.find((job) => job.id === id)?.name;
      return `${name.split(":")[0]}\n`;
    });

    const description = `${
      coupons.length ? `${coupons}\n` : ""
    }${servicesFormatted.toString().replaceAll(",", "").trim()}${
      recommendedServicesFormatted.length
        ? `${servicesFormatted.length ? "\n" : ""}${recommendedServicesFormatted
            .toString()
            .replaceAll(",", "")
            .trim()}`
        : ""
    }${otherText ? `\n${otherText}` : ""}`.trim();
    // }${otherText ? `\n${otherText}` : ""}`;

    const body = {
      data: {
        appointmentDetails: {
          firstName: personalInfo.firstName.trim(),
          lastName: personalInfo.lastName.trim(),
          email: personalInfo.email.trim(),
          phone: personalInfo.phone,
          zipCode: personalInfo.zip,
          scorpionSessionId: personalInfo.scorpionSessionId,
          contactPreferences: contactPreferences,
          address: "",
          VIN: "",
          make: vehicleInfo.make,
          model: vehicleInfo.model,
          year: vehicleInfo.year,
          color: vehicleInfo.color,
          services: services,
          description: description,
          isAfterHoursDropoff,
          recommendedServices: recommendedServices,
          otherServices: [],
          shuttleServiceSelected: isDropoff === "Yes" && selectedShuttleService,
          notes: otherText,
          alternatePhone: personalInfo.altPhone,
          isFirstTimeVisiting: personalInfo.firstVisit === "Yes",
        },
        // shopId: franchise.shopId,
        shopId:
          process.env.REACT_APP_ENV === "DEV" ||
          process.env.REACT_APP_ENV === "TEST"
            ? "211"
            : franchise.shopId,
        userId: "",
        slotId: temporarySlotId,
        date: selectedSlot.dropoffDate
          ? selectedSlot.dropoffDate
          : selectedSlot.date,
        slot: selectedSlot.time,
        isDropoff: isDropoff === "Yes",
        mktCookies: mktCookies,
      },
    };

    const res = await axios.post(`${cbaBaseAzureUrl}/bookAppointment`, body);
    if (res.status === 200) {
      dispatch(bookAppointmentSuccess({ res }));
    } else {
      // Handle failed status codes
      throw { message: res.data.data };
    }
  } catch (e) {
    // Handle booking error when times are not available
    if (e.response.status === 409) {
      const timeModal = <TimeRanOutModal />;
      dispatch(setModalContents(timeModal));
      return console.error(e.message);
    } else {
      dispatch(setRedirectError(true));
      return console.error(e.message);
    }
  }
};

export const confirmAppointment = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {
      personalInfo,
      vehicleInfo,
      franchise,
      selectedSlot,
      confirmationNumber,
      isDropoff,
      selectedServices,
      selectedShuttleService,
      otherText,
      dgbCustomerData,
      dgbVehicleId,
      recommendedServices,
    } = state.scheduler;

    const services = getServices(selectedServices, franchise);

    const servicesFormatted = selectedServices.map((service, i) => {
      // console.log(service);
      return franchise.services[service].text === "Other"
        ? `${otherText}\n`
        : `${franchise.services[service].text}\n`;
    });

    const recommendedServicesFormatted = recommendedServices.map((id) => {
      const name = dgbCustomerData.garage
        ?.find((vehicle) => vehicle.garageId === dgbVehicleId)
        .recommendedHistory[0]?.jobs.find((job) => job.id === id)?.name;
      return `${name.split(":")[0]}\n`;
    });

    // const description =
    //   servicesFormatted.toString().replaceAll(",", "").trim() +
    //   (!selectedServices.includes(16) ? `\n${otherText}` : "");

    const description = `${servicesFormatted
      .toString()
      .replaceAll(",", "")
      .trim()}${
      recommendedServicesFormatted
        ? `${servicesFormatted.length ? "\n" : ""}${recommendedServicesFormatted
            .toString()
            .replaceAll(",", "")
            .trim()}`
        : ""
    }${!selectedServices.includes(16) ? `\n${otherText}` : ""}`;

    const body = {
      first_name: personalInfo.firstName,
      last_name: personalInfo.lastName,
      phone: personalInfo.phone,
      email: personalInfo.email,
      veh_yr: vehicleInfo.year,
      veh_mk: vehicleInfo.make,
      veh_mod: vehicleInfo.model,
      slot_time: selectedSlot.time,
      slot_date: selectedSlot.dropoffDate
        ? selectedSlot.dropoffDate
        : selectedSlot.date,
      shop_id:
        process.env.REACT_APP_ENV === "DEV" ||
        process.env.REACT_APP_ENV === "TEST"
          ? "99999"
          : franchise.shopId,
      appt_id: confirmationNumber,
      isDropoff: isDropoff === "Yes",
      shuttleServiceSelected: isDropoff === "Yes" && selectedShuttleService,
      description: description,
      services: services,
    };

    const res = await axios.post(`${cbaBaseAzureUrl}/createMarketoEmail`, body);
    // console.log("res status", res.status, res.statusText);
    if (res.status === 200) {
      dispatch(confirmAppointmentSuccess({ res }));
    } else {
      // Handle failed status codes
      throw { message: res.data.data };
    }
  } catch (e) {
    dispatch(setRedirectError(true));
    return console.error(e.message);
  }
};

// Cancel appointments
export const cancelAppointment = (bookingId) => async (dispatch) => {
  try {
    // const res = await axios.get(`${baseUrl}/api/v1/slotbooking/cancelAppointment?refId=${bookingId}`)
    const res = await axios.post(`${cbaBaseAzureUrl}/cancel`, {
      apptId: parseInt(bookingId),
    });

    if (res.status === 200) {
      dispatch(getCancellationSuccess(true));
    } else {
      // Handle failed status codes
      throw { message: res.data.data };
    }
  } catch (e) {
    if (e.response.status === 418) {
      dispatch(getCancellationSuccess(false));
    } else {
      dispatch(setRedirectError(true));
    }
    return console.error(e.message);
  }
};
