import "./DropoffIcon.css";
import { ReactComponent as IconStayAndWait } from "../assets/icons/stay-and-wait2.svg";
import { ReactComponent as IconDropoff } from "../assets/icons/drop-off.svg";
import { ReactComponent as IconSelected } from "../assets/icons/selected-check.svg";
import { ReactComponent as IconStayAndWaitDisabled } from "../assets/icons/stay-and-wait-disabled.svg";

import { useMemo } from "react";

export default function DropoffIcon({
  selected,
  handleClick,
  isDropoff,
  disabled,
}) {
  // Handler for our click
  const handleButtonClicked = (e) => {
    e.preventDefault();

    // Validate that we have a click function
    if (handleClick && typeof handleClick === "function") {
      // Notify parent that dropoff or stay-and-wait was clicked
      handleClick(isDropoff);
    }
  };

  const icon = useMemo(() => {
    if (selected) {
      return <IconSelected className="dropoff-svg-icon" />;
    }

    if (disabled) {
      return <IconStayAndWaitDisabled className="dropoff-svg-icon" />;
    }

    return isDropoff ? (
      <IconDropoff className="dropoff-svg-icon" />
    ) : (
      <IconStayAndWait className="dropoff-svg-icon" />
    );
  }, [selected, isDropoff]);

  return (
    <button
      className={`dropoff-icon${selected ? " selected" : ""}`}
      role="switch"
      onClick={handleButtonClicked}
      aria-checked={selected}
      disabled={disabled}
      aria-label={isDropoff ? "Drop Off Vehicle" : "Stay and Wait"}
    >
      <div>{icon}</div>

      <p className="dropoff-icon-label">
        {isDropoff ? "Drop Off Vehicle" : "Stay and Wait"}
      </p>
      <p className="dropoff-icon-text">
        {isDropoff
          ? "Leave your vehicle with us and we’ll reach out before any work begins."
          : "We’ll do our  best to have you in and out in about an hour."}
      </p>
    </button>
  );
}
