import CalendarView from "./DateTime/CalendarView.jsx";
import WeekView from "./DateTime/WeekView.jsx";
import BottomNav from "../components/BottomNav.jsx";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { blockSlotTemporarily, setCurrentStep } from "../store/scheduler.js";

import "./DateTime.css";

export default function DateTime() {
  // TODO: Change times available sum to not include after hours slot if waiter

  // Redux
  const showCalendarView = useSelector(
    (state) => state.scheduler.dateTimeShowCalendar
  );
  const urlSlug = useSelector((state) => state.scheduler.franchise.urlSlug);
  const selectedSlot = useSelector((state) => state.scheduler.selectedSlot);
  const temporarySlotId = useSelector(
    (state) => state.scheduler.temporarySlotId
  );
  const selectedServices = useSelector(
    (state) => state.scheduler.selectedServices
  );

  const recommendedServices = useSelector(
    (state) => state.scheduler.recommendedServices
  );
  const isDropoff = useSelector((state) => state.scheduler.isDropoff);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getDateTimeView = () => {
    if (showCalendarView) {
      return <CalendarView />;
    }

    return <WeekView />;
  };

  // COMPONENT DID MOUNT
  useEffect(() => {
    dispatch(setCurrentStep(3));
  }, []);

  // Redirect if we are ever missing services
  useEffect(() => {
    if (!selectedServices.length && !recommendedServices.length && urlSlug) {
      // history.push(`/${urlSlug}/`);
      navigate(`/${urlSlug}`);
    }
  }, [urlSlug, selectedServices, recommendedServices]);

  const handleCancel = (e) => {
    e.preventDefault();
    // history.goBack();
    navigate(-1);
  };
  const handleContinue = (e) => {
    e.preventDefault();

    // Only block the slot if we haven't already AND we are not a dropoff
    // if(!temporarySlotId && isDropoff === 'No')
    // {
    //   dispatch(blockSlotTemporarily());
    // }
    // history.push(`/${urlSlug}/vehicle-info`);
    navigate(`/${urlSlug}/personal-info`);
  };

  return (
    <div>
      <div className="datetime">
        <h1 className="heading">What day and time work best for you?</h1>
        <p className="datetime-description">
          Our current availability is listed below. Please select what is most
          convenient for you.
        </p>
        {getDateTimeView()}
      </div>
      <BottomNav
        enableContinue={selectedSlot.time}
        handleCancel={handleCancel}
        handleContinue={handleContinue}
        cancelLabel="Previous"
      />
    </div>
  );
}
