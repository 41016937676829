import "./ComingSoonScreen.css";
import "../components/Header.css";
import { useSelector } from "react-redux";

export default function ComingSoonScreen() {
  const { franchiseName, city, state, urlSlug } = useSelector(
    (state) => state.scheduler.franchise
  );
  const { facebook } = useSelector(
    (state) => state.scheduler.franchise.socialLinks
  );

  return (
    <div className={"coming-soon-background"}>
      <div className={"coming-soon-background-image"}>
        <div className={"coming-soon-container"}>
          <div className={"coming-soon-heading"}>
            <h1> Coming Soon</h1>
            <h2>{`A new car care experience is coming to ${city}, ${state}!`}</h2>
          </div>
          <div className={"coming-soon-info"}>
            <p>
              {"Can't wait for our Christian Brothers Automotive "}
              <a href={`https://www.cbac.com/${urlSlug} `}>{franchiseName}</a>

              {" location to open? Neither can we!"}
            </p>
            {facebook && facebook !== "" ? (
              <p>
                {" Check out our "}
                <a href={`${facebook}`}>{"Facebook"}</a>
                {" page for special offers and updates on the grand opening."}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
