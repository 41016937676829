import "./AfterHoursModal.css";
import { ReactComponent as IconClose } from "../../assets/icons/icons-close2.svg";
import { useDispatch } from "react-redux";
import { setModalContents } from "../../store/scheduler";

export default function AfterHoursModal() {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setModalContents(""));
  };

  const handleClose = (e) => {
    e.preventDefault();
    closeModal();
  };

  return (
    <div className="after-hours-modal">
      <button className="after-hours-modal-close" onClick={handleClose}>
        <IconClose />
      </button>
      <div className="title">
        <p>You’ve selected After Hours Drop Off.</p>
        <p className="whats-next">What's next?</p>
      </div>

      <div className="after-hours-modal-container">
        {/* 1 */}
        <div className="number-container">
          <div className="number">
            <div>1</div>
          </div>
          <div className="step-text">Remove any valuables</div>
        </div>

        {/* 2 */}
        <div className="number-container">
          <div className="number">
            <div>2</div>
          </div>
          <div className="step-text">Lock your vehicle</div>
        </div>

        {/* 2 */}
        <div className="number-container">
          <div className="number">
            <div>3</div>
          </div>
          <div className="step-text">
            Place only your vehicle key through slot in the door or as
            instructed (see signage near front door)
          </div>
        </div>
      </div>
      <p className="contact-sentence">
        We will contact you prior to performing any work. Thank you!
      </p>
      <button className="done-button" onClick={handleClose}>
        Done
      </button>
    </div>
  );
}
