import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import {
  setPersonalInfo,
  setCurrentStep,
  setValidFields,
  setDisplayedErrors,
} from "../store/scheduler";
import RadioButton from "../components/RadioButton.jsx";
import PhoneInput from "react-phone-input-2";

import BottomNav from "../components/BottomNav.jsx";
import "./PersonalInfoScreen.css";

export default function PersonalInfoScreen() {
  const {
    franchise,
    personalInfo,
    validFields,
    displayedErrors,
    selectedServices,
    recommendedServices,
    dgbCustomerData,
  } = useSelector((state) => state.scheduler);
  const urlSlug = useSelector((state) => state.scheduler.franchise.urlSlug);
  const [phoneValue, setPhoneValue] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCancel = (e) => {
    e.preventDefault();
    // history.goBack();
    navigate(-1);
  };

  const handlePhoneChange = (value, country, e, formattedValue) => {
    updateField(e);
  };

  const handleZipChange = (e) => {
    // Strip all but numbers
    const strippedNumber = e.target.value.replace(/[^0-9]+/g, "");
    const cleanedEvent = { ...e };
    cleanedEvent.target.value = strippedNumber;

    // Set our state
    updateField(cleanedEvent);
  };

  // Error handle on blur for field type
  const validateField = (e) => {
    // Make sure that we have 10 characters for phone
    if (e.target.name === "phone") {
      const strippedNumber = e.target.value.replace(/\D+/g, "");
      console.log(
        "strippedNumber",
        strippedNumber,
        strippedNumber.length < 10,
        !strippedNumber.match(/^([2-9])(\d{2})(\d{3})(\d{4})$/gm)
      );
      if (
        strippedNumber.length < 10 ||
        !strippedNumber.match(/^([2-9])(\d{2})(\d{3})(\d{4})$/gm)
        // !strippedNumber.match(/^([2-9])(\d{2})(\d{3})(\d{4})$/gm)
      ) {
        dispatch(setValidFields({ [e.target.name]: false }));
        dispatch(setDisplayedErrors({ [e.target.name]: true }));
        return;
      }
    }

    // Check length
    if (e.target.value.length < 1) {
      dispatch(setValidFields({ [e.target.name]: false }));
      dispatch(setDisplayedErrors({ [e.target.name]: true }));
      return;
    }

    // Make sure that we have a valid email
    if (e.target.name === "email") {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(String(e.target.value).toLowerCase().trim())) {
        dispatch(setDisplayedErrors({ [e.target.name]: true }));
        dispatch(setValidFields({ [e.target.name]: false }));
        return;
      }
    }

    if (e.target.name === "zip") {
      if (e.target.value.length !== 5) {
        dispatch(setDisplayedErrors({ [e.target.name]: true }));
        dispatch(setValidFields({ [e.target.name]: false }));
        return;
      }
    }

    // Invalidate if we only enter spaces (to avoid putting in our name)
    if (e.target.name === "firstName" || e.target.name === "lastName") {
      // Check for a single space
      if (!e.target.value.trim()) {
        dispatch(setDisplayedErrors({ [e.target.name]: true }));
        dispatch(setValidFields({ [e.target.name]: false }));
        return;
      }
    }

    // We have no error for this field, return true
    dispatch(setValidFields({ [e.target.name]: true }));
    dispatch(setDisplayedErrors({ [e.target.name]: false }));
  };

  // Dispatch to update field in redux
  const updateField = (e) => {
    const fieldName = e.target.name;

    // format our phone field
    if (fieldName === "phone") {
      const strippedNumber = e.target.value.replace(/\D+/g, "");
      dispatch(setPersonalInfo({ [fieldName]: strippedNumber }));
      validateField(e);
      return;
    } else if (fieldName === "firstName" || fieldName === "lastName") {
      const cleanedName = e.target.value.replace(/[^a-zA-Z ]/g, "");
      dispatch(setPersonalInfo({ [fieldName]: cleanedName }));
      validateField(e);
      return;
    } else if (fieldName === "email") {
      const cleanedEmail = e.target.value.trim();
      dispatch(setPersonalInfo({ [fieldName]: cleanedEmail }));
      validateField(e);
      return;
    } else if (fieldName === "zip") {
      const cleanedZip = e.target.value.trim();
      dispatch(setPersonalInfo({ [fieldName]: cleanedZip }));
      validateField(e);
      return;
    }

    dispatch(setPersonalInfo({ [fieldName]: e.target.value }));
    validateField(e);
  };

  const validatePrefilledFields = () => {
    if (dgbCustomerData && personalInfo) {
      dgbCustomerData.profile?.firstName &&
        validateField({
          target: {
            name: "firstName",
            value: dgbCustomerData.profile?.firstName,
          },
        });
      dgbCustomerData.profile?.lastName &&
        validateField({
          target: {
            name: "lastName",
            value: dgbCustomerData.profile?.lastName,
          },
        });
      dgbCustomerData.email &&
        validateField({
          target: {
            name: "email",
            value: dgbCustomerData.email,
          },
        });
      dgbCustomerData.profile?.phone &&
        validateField({
          target: {
            name: "phone",
            value: dgbCustomerData.profile?.phone,
          },
        });
    }
  };

  //! Hacky fix to clear value and re-enable phone formatting
  useEffect(() => {
    setTimeout(() => {
      setPhoneValue(personalInfo.phone);
    }, 0.001);
  });

  const handleRadioChange = (e) => {
    dispatch(setPersonalInfo({ firstVisit: e.target.value }));

    // Clear any validation errors here
    dispatch(setValidFields({ firstVisit: true }));
    dispatch(setDisplayedErrors({ firstVisit: false }));
  };

  // Memo for our valid fields
  const enableContinue = useMemo(() => {
    let allFieldsValid = true;
    const keys = Object.keys(validFields);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const isValid = validFields[key];

      if (!isValid) {
        // return false
        allFieldsValid = false;
        break;
      }
    }

    return allFieldsValid;
  }, [validFields]);

  const validateAllFields = () => {
    let allFieldsValid = true;
    const keys = Object.keys(validFields);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const isValid = validFields[key];

      if (!isValid) {
        // Dispatch our error
        dispatch(setDisplayedErrors({ [key]: true }));

        // return false
        allFieldsValid = false;
      }
    }

    return allFieldsValid;
  };

  // We need to validate all of our given fields
  const handleContinue = (e) => {
    e.preventDefault();

    // If true, continue
    if (validateAllFields()) {
      // Navigate to personal information page
      // history.push(`/${franchise.urlSlug}/review`);
      navigate(`/${franchise.urlSlug}/review`);
      // navigate(`/${urlSlug}/review`);
    }
  };

  // Redirect if we are ever missing services
  useEffect(() => {
    if (!selectedServices.length && !recommendedServices.length && urlSlug) {
      // history.push(`/${urlSlug}/`);
      navigate(`/${urlSlug}`);
    }
  }, [urlSlug, selectedServices, recommendedServices, navigate]);

  // COMPONENT DID MOUNT
  useEffect(() => {
    dispatch(setCurrentStep(4));
  }, [dispatch]);

  useEffect(() => {
    if (dgbCustomerData) {
      dispatch(
        setPersonalInfo({
          firstName: personalInfo.firstName
            ? personalInfo.firstName
            : dgbCustomerData.profile?.firstName,
          lastName: personalInfo.lastName
            ? personalInfo.lastName
            : dgbCustomerData.profile?.lastName ?? "",
          email: personalInfo.email
            ? personalInfo.email
            : dgbCustomerData.email ?? "",
          phone: personalInfo.phone
            ? personalInfo.phone
            : dgbCustomerData.profile?.phone ?? "",
        })
      );
      validatePrefilledFields();
    }
  }, [dgbCustomerData]);

  console.log(personalInfo);

  return (
    <div className="personal-info">
      <h1 className="heading">
        Almost there! Just need a little more info about you.
      </h1>
      <form className="personal-info-form">
        {/* Column 1 */}
        <div className="form-row">
          <div
            className={`input-item${
              displayedErrors.firstName ? " show-error" : ""
            }`}
          >
            {displayedErrors.firstName && (
              <p className="personal-info-error">
                Please enter your first name
              </p>
            )}
            <label htmlFor="info-firstname">First Name</label>
            <input
              id="info-firstname"
              type="text"
              placeholder="First name*"
              value={personalInfo.firstName}
              name="firstName"
              onChange={updateField}
              onBlur={validateField}
              aria-label={"firstName"}
            />
          </div>

          <div
            className={`input-item${
              displayedErrors.lastName ? " show-error" : ""
            }`}
          >
            {displayedErrors.lastName && (
              <p className="personal-info-error">Please enter your last name</p>
            )}
            <label htmlFor="info-lastname">Last Name</label>
            <input
              id="info-lastname"
              type="text"
              placeholder="Last name*"
              value={personalInfo.lastName}
              onChange={updateField}
              name="lastName"
              onBlur={validateField}
              aria-label={"lastName"}
            />
          </div>

          <div
            className={`input-item${
              displayedErrors.email ? " show-error" : ""
            }`}
          >
            {displayedErrors.email && (
              <p className="personal-info-error">
                Please enter a valid email address
              </p>
            )}
            <label htmlFor="info-email">Email Address</label>
            <input
              id="info-email"
              type="text"
              placeholder="Email address*"
              value={personalInfo.email}
              onChange={updateField}
              name="email"
              onBlur={validateField}
              aria-label={"email"}
            />
          </div>
        </div>

        {/* Row 2 */}
        <div className="form-row phone-row">
          <div
            className={`input-item${
              displayedErrors.phone ? " show-error" : ""
            }`}
          >
            {displayedErrors.phone && (
              <div className="personal-info-error">
                Please enter a valid phone number
              </div>
            )}
            <label htmlFor="info-phone">Phone Number</label>
            <PhoneInput
              id="info-phone"
              country="us"
              prefix=""
              value={phoneValue}
              onChange={handlePhoneChange}
              placeholder="Phone number*"
              disableDropdown
              disableCountryGuess
              disableCountryCode
              defaultMask={`(...) ... ....`}
              alwaysDefaultMask={true}
              onBlur={validateField}
              inputProps={{
                name: "phone",
                ["aria-label"]: "phone",
              }}
            />
          </div>

          <div
            className={`input-item${displayedErrors.zip ? " show-error" : ""}`}
          >
            {displayedErrors.zip && (
              <p className="personal-info-error">
                Please enter a valid zip code
              </p>
            )}
            <label htmlFor="info-zip">Zip Code</label>
            <input
              id="info-zip"
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              placeholder="Zip Code*"
              minLength="5"
              maxLength="5"
              value={personalInfo.zip}
              onChange={handleZipChange}
              name="zip"
              onBlur={validateField}
              autoComplete="postal-code"
              aria-label={"zip"}
            />
          </div>
        </div>
      </form>
      <div className="personal-info-first-time">
        {/*<p className="first-time-question">Is this your first time visiting our {franchise.franchiseeName} store?*</p>*/}
        <p className="first-time-question">
          Is this your first time visiting our {franchise.franchiseName} store?*
        </p>
        <div className="first-time-selection-container">
          {displayedErrors.firstVisit && (
            <p className="personal-info-error">Please choose Yes or No</p>
          )}
          <div
            className={`personal-info-radio${
              displayedErrors.firstVisit ? " show-error" : ""
            }`}
          >
            <RadioButton
              name={"first-time"}
              label="Yes"
              value="Yes"
              checked={personalInfo.firstVisit}
              ariaLabel={"ftcYes"}
              onChange={handleRadioChange}
            />
            <RadioButton
              name={"first-time"}
              label="No"
              value="No"
              checked={personalInfo.firstVisit}
              ariaLabel={"ftcNo"}
              onChange={handleRadioChange}
            />
          </div>
        </div>
      </div>

      <BottomNav
        enableContinue={enableContinue}
        handleCancel={handleCancel}
        handleContinue={handleContinue}
        cancelLabel="Previous"
        fakeDisabled={!enableContinue}
      />
    </div>
  );
}
