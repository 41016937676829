import { useEffect } from "react";
import { ReactComponent as IconCalendar } from "../../assets/icons/icons-calendar.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setDateTimeShowCalendar,
  getAvailableSlots,
  setCurrentlyOpenDateDropdown,
} from "../../store/scheduler.js";
import DatePicker from "../../components/DatePicker.jsx";
import TagManager from "react-gtm-module";

import "./WeekView.css";
import { useMemo } from "react";
import dayjs from "dayjs";

export default function WeekView() {
  const dispatch = useDispatch();

  // const {franchiseeName} = useSelector(state => state.scheduler.franchise);
  const { franchiseName } = useSelector((state) => state.scheduler.franchise);
  const showCalendarView = useSelector(
    (state) => state.scheduler.dateTimeShowCalendar
  );
  const {
    franchiseSlots,
    currentlyOpenDateDropdown,
    selectedSlot,
    selectedServices,
    recommendedServices,
  } = useSelector((state) => state.scheduler);

  const startDate = Object.keys(franchiseSlots)[0];

  useEffect(() => {
    // COMPONENT DID MOUNT

    // Fix crash on refresh
    if (!selectedServices.length && !recommendedServices.length) return;

    // if(franchiseeName){
    if (franchiseName) {
      dispatch(getAvailableSlots());
    }
    // }, [franchiseeName, selectedServices]);
  }, [franchiseName, selectedServices]);

  const handleGADatepickerEvent = () => {
    const dataLayer = {
      event: "event_calendar_view_clicked",
    };

    TagManager.dataLayer({
      dataLayer,
    });
  };

  const changeView = (e) => {
    e.preventDefault();
    handleGADatepickerEvent();
    dispatch(setDateTimeShowCalendar(!showCalendarView));
  };

  // TODO: We need to save the week offset here. This will allow us to populate this page with the current week upon returning to it
  const handlePrev = (e) => {
    e.preventDefault();
    const currentStart = dayjs(startDate);
    const newStart = currentStart.subtract(7, "day");
    dispatch(getAvailableSlots(newStart));
  };

  // TODO: We need to set the week offset here.
  const handleNext = (e) => {
    e.preventDefault();
    const currentStart = dayjs(startDate);
    const newStart = currentStart.add(7, "day");
    dispatch(getAvailableSlots(newStart));
  };

  const isToday = useMemo(() => {
    const today = dayjs();
    const currentStart = dayjs(startDate);
    return currentStart.format("DDMMYYYY") === today.format("DDMMYYYY");
  }, [startDate]);

  // Memo to generate franchise slots from our API object
  const generateDatePickers = useMemo(() => {
    return Object.entries(franchiseSlots).map(([key, slot], index) => {
      // slot.slots.filter((item) =>
      //     item.fromTime.split(":")[0] === breakStartTime.toString().split(":")[0])
      // console.log("key", `slot_${index}`, "data", {key, ...slot});
      // console.log("slot", slot)
      // console.log("slot filter", slot.slots.filter((item) =>
      //     item.fromTime.split(":")[0] === breakStartTime.toString().split(":")[0]));
      return <DatePicker key={`slot_${index}`} data={{ key, ...slot }} />;
    });
  }, [franchiseSlots]);

  // Open 'Today' when we load if no slot picked
  useEffect(() => {
    // Make sure that we are in today view AND we don't have an open dropdown
    if (isToday && startDate && !currentlyOpenDateDropdown) {
      dispatch(setCurrentlyOpenDateDropdown(startDate));
    }
  }, [isToday, startDate]);

  // Open currently selected day automatically
  // ! This does not automatically navigate us to the correct 'week'
  // ! For example, if the user chooses a slot on page 2, it won't automatically open it to page 2
  useEffect(() => {
    if (selectedSlot && selectedSlot.date) {
      dispatch(setCurrentlyOpenDateDropdown(selectedSlot.date));
    }
  }, []);

  // Hide page without data
  if (!Object.keys(franchiseSlots).length) {
    return <></>;
  }

  // Returns our Month Year for the current week
  const getCurrentMonth = () => {
    {
      const today = dayjs(startDate);
      return today.format("MMMM YYYY");
    }
  };

  return (
    <div className="weekview">
      <div className="weekview-header">
        <p>{getCurrentMonth()}</p>
        <button className="datetime-button-link" onClick={changeView}>
          <IconCalendar /> Calendar view
        </button>
      </div>

      <div className="weekview-picker-container">{generateDatePickers}</div>
      <div className="weekview-nav">
        {isToday ? (
          <div></div>
        ) : (
          <button className="datetime-button-link" onClick={handlePrev}>
            Prev 7 days
          </button>
        )}
        <button className="datetime-button-link" onClick={handleNext}>
          Next 7 days
        </button>
      </div>
    </div>
  );
}
