import { useSelector, useDispatch } from "react-redux";
import { useMemo, useEffect } from "react";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import {
  confirmAppointment,
  setCurrentStep,
  setModalContents,
} from "../store/scheduler";
import { ReactComponent as IconInfo } from "../assets/icons/icons-info.svg";
import BottomNav from "../components/BottomNav.jsx";
import AfterHoursModal from "../components/modals/AfterHoursModal";

import { ReactComponent as IconTools } from "../assets/icons/icons-tools.svg";
import { ReactComponent as IconCalendar } from "../assets/icons/icons-calendar.svg";
import { ReactComponent as IconDirections } from "../assets/icons/icons-directions.svg";
import { ReactComponent as IconUser } from "../assets/icons/icons-user.svg";
import { ReactComponent as IconRadioEmpty } from "../assets/icons/radio-check-empty.svg";
import { ReactComponent as IconRadioSelected } from "../assets/icons/radio-check-selected.svg";

// Info Card on Confirmation Screen
const InfoCard = ({ section, children }) => {
  const sectionIcon = useMemo(() => {
    if (section === "service") return <IconTools />;
    else if (section === "date") return <IconCalendar />;
    else if (section === "store") return <IconDirections />;
    else if (section === "personal") return <IconUser />;
  }, [section]);

  return (
    <div className="review-info-card">
      <div className="review-icon">{sectionIcon}</div>
      <div className="info-card-content">{children}</div>
    </div>
  );
};

export default function ConfirmationScreen() {
  const {
    personalInfo,
    franchise,
    selectedShuttleService,
    selectedSlot,
    vehicleInfo,
    selectedServices,
    otherText,
    isDropoff,
    confirmationNumber,
    isAfterHoursDropoff,
  } = useSelector((state) => state.scheduler);
  const urlSlug = useSelector((state) => state.scheduler.franchise.urlSlug);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Memo for service list
  const serviceList = useMemo(() => {
    if (selectedServices) {
      const serviceMap = selectedServices.map((service, index) => {
        const currentService = franchise.services[service];
        return (
          <li key={`${currentService.serviceId}_${index}`}>
            {currentService.text}
          </li>
        );
      });

      if (otherText) {
        serviceMap.push(
          <li className="review-other-item" key={`other_service-Key`}>
            Other: <span className="review-other-text">{otherText}</span>
          </li>
        );
      }

      return serviceMap;
    }

    return <></>;
  }, [selectedServices]);

  // Format our date for display
  const formattedDate = useMemo(() => {
    if (selectedSlot) {
      const formatted = dayjs(selectedSlot.date);
      return formatted.format("dddd, MMMM D, YYYY");
    }
  }, [selectedSlot]);

  // Format phone and alt phone numbers
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  // Format our time correctly
  const formattedTime = useMemo(() => {
    const unformattedTime = selectedSlot.time;

    // Convert our hours into the correct Label for 12 hour time
    const slotHours = Number(unformattedTime.substring(0, 2));
    const minutes = unformattedTime.split(":")[1];
    const hours = slotHours < 12 ? slotHours : ((slotHours + 11) % 12) + 1;
    const suffix = unformattedTime.substr(0, 2) > 11 ? "PM" : "AM";
    const label = `${hours}:${minutes} ${suffix}`;

    return label;
  }, [selectedSlot]);

  // Issues print command to browser
  const handlePrint = (e) => {
    window.print();
  };

  // Do nothing if we click on the shuttle service here
  const handleShuttleClicked = (e) => {
    e.preventDefault();
  };

  // Component Did Mount
  useEffect(() => {
    if (
      confirmationNumber !== 0 &&
        (process.env.REACT_APP_ENV === "STG" ||
            process.env.REACT_APP_ENV === "PROD")
    ) {
      dispatch(confirmAppointment());
    }
    dispatch(setCurrentStep(5));
  }, []);

  // Redirect if we are ever missing a confirmation number
  useEffect(() => {
    if (!confirmationNumber && urlSlug) {
      // history.push(`/${urlSlug}/`);
      navigate(`/${urlSlug}/`);
    }
  }, [confirmationNumber, urlSlug]);

  // Handler for after hours dropoff
  const handleAfterHoursClicked = (e) => {
    e.preventDefault();

    // Show our modal
    const modal = <AfterHoursModal />;

    dispatch(setModalContents(modal));
  };

  // Dropoff Text
  const getDropoffText = () => {
    if (isDropoff !== "Yes") {
      return "Stay and Wait";
    }

    if (isAfterHoursDropoff) {
      return (
        <p className="after-hours-label">
          After Hours Drop Off
          <button
            className="dropoff-info-button"
            onClick={handleAfterHoursClicked}
          >
            <IconInfo />
          </button>
        </p>
      );
    } else {
      return "Dropoff";
    }
  };

  //   TODO: Add disclaimer text about dropoff guarantee

  //! We are using .review-screen classes here for the sake of time. This should allow styling to remain consistent across screens
  return (
    <div className="review-screen">
      <div className="review-container">
        <h1 className="heading booking-heading">
          Appointment Confirmed, {personalInfo.firstName}!
        </h1>
        <p className="review-sentence">
          We are excited to see your {vehicleInfo.year} {vehicleInfo.make}{" "}
          {vehicleInfo.model}.
        </p>
        {/*<p className="confirmation-sentence">Though we strive to provide same day service for our guests,*/}
        {/*  booking a dropoff appointment does not guarantee same day service.</p>*/}
        {/* <p className="review-sentence">Appointment ID #{confirmationNumber}</p> */}
        <div className="info-card-container">
          <InfoCard section="service">
            <ul className="review-services">{serviceList}</ul>
            <div className="review-text">{getDropoffText()}</div>
          </InfoCard>
          <InfoCard className="review-section" section="date">
            <div className="review-heading">
              {formattedDate}{" "}
              {!isAfterHoursDropoff ? `at ${formattedTime}` : ""}
            </div>
          </InfoCard>
          <InfoCard section="store">
            {/*<div className="review-heading">{franchise.franchiseeName}</div>*/}
            <div className="review-heading">{franchise.franchiseName}</div>
            <div className="review-text">{franchise.street}</div>
            <div className="review-text">
              {franchise.city}, {franchise.state} {franchise.zipCode}
            </div>
            <a className="review-tel" href={`tel:${franchise.phone}`}>
              {franchise.phone}
            </a>
          </InfoCard>
          <InfoCard section="personal">
            <div className="review-heading">
              {personalInfo.firstName} {personalInfo.lastName}
            </div>
            <div className="review-text">{personalInfo.email}</div>
            <div className="review-text">
              {formatPhoneNumber(personalInfo.phone)}
            </div>
            {personalInfo.altPhone && (
              <div className="review-text">
                {formatPhoneNumber(personalInfo.altPhone)}
              </div>
            )}
          </InfoCard>
        </div>

        <div className="review-shuttle-wrapper">
          <div
            className={`dropoff-shuttle-container${
              isDropoff !== "Yes" || isAfterHoursDropoff ? " disabled" : ""
            }${selectedShuttleService ? " selected" : ""}`}
          >
            <div className="shuttle-left">
              <button
                id="shuttle-radio-button"
                className="shuttle-selector"
                role="switch"
                aria-checked={selectedShuttleService}
                disabled={isDropoff !== "Yes" || isAfterHoursDropoff}
                onClick={handleShuttleClicked}
                style={{ cursor: "default" }}
              >
                {selectedShuttleService ? (
                  <IconRadioSelected />
                ) : (
                  <IconRadioEmpty />
                )}
              </button>
            </div>
            <div className="shuttle-right">
              <label
                htmlFor="shuttle-radio-button"
                className="shuttle-right-title"
              >
                Shuttle Service
              </label>
              {isDropoff !== "Yes" || isAfterHoursDropoff ? (
                <p className="shuttle-right-description">
                  Shuttle Service is unavailable for Stay & Wait or After Hours
                  Dropoff.
                </p>
              ) : (
                <span>
                  <p className="shuttle-right-description">
                    This location provides a complimentary shuttle service
                    during normal business hours.
                  </p>
                  {!selectedShuttleService ? (
                    <p className="shuttle-right-description">
                      Will you need a ride?
                    </p>
                  ) : null}
                </span>
              )}
              {/*<p className="shuttle-right-description">This location provides a complimentary shuttle service during normal business hours.</p>*/}
              {/*{!selectedShuttleService ? <p className="shuttle-right-description">Will you need a ride?</p>: null}*/}
            </div>
          </div>
        </div>

        <BottomNav
          handleContinue={handlePrint}
          hideCancel
          enableContinue={true}
          continueLabel="Print"
        />
      </div>
    </div>
  );
}
