import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconTools } from "../assets/icons/icons-tools.svg";
import { ReactComponent as IconCalendar } from "../assets/icons/icons-calendar.svg";
import { ReactComponent as IconDirections } from "../assets/icons/icons-directions.svg";
import { ReactComponent as IconUser } from "../assets/icons/icons-user.svg";
import {
  bookAppointment,
  clearSession,
  releaseTemporarySlot,
  selectTimeSlot,
  setCurrentStep,
  setModalContents,
  setSelectedShuttleService,
} from "../store/scheduler";
import CancelModal from "../components/modals/CancelModal.jsx";
import TimeModal from "../components/modals/TimeModal.jsx";
import LocationModal from "../components/modals/LocationModal.jsx";
import BottomNav from "../components/BottomNav.jsx";
import AfterHoursModal from "../components/modals/AfterHoursModal";
import { ReactComponent as IconInfo } from "../assets/icons/icons-info.svg";
import { ReactComponent as IconRadioEmpty } from "../assets/icons/radio-check-empty.svg";
import { ReactComponent as IconRadioSelected } from "../assets/icons/radio-check-selected.svg";

import TagManager from "react-gtm-module";

import dayjs from "dayjs";

import "./ReviewScreen.css";

// Info Card on review screen
const InfoCard = ({ section, link, children, handleEdit }) => {
  const navigate = useNavigate();

  const sectionIcon = useMemo(() => {
    if (section === "service") return <IconTools />;
    else if (section === "date") return <IconCalendar />;
    else if (section === "store") return <IconDirections />;
    else if (section === "personal") return <IconUser />;
  }, [section]);

  const handleEditClicked = (e) => {
    e.preventDefault();

    // Callback to fire location edit
    if (handleEdit) {
      handleEdit();
      return;
    }

    // Edit Clicked
    const dataLayer = {
      event: "event_edit_information",
      editSection: section,
    };

    // Push to dataLayer
    TagManager.dataLayer({
      dataLayer,
    });

    if (navigate) {
      // history.push(link);
      navigate(link);
    }
  };

  return (
    <div className="review-info-card">
      <div className="review-icon">{sectionIcon}</div>
      <div className="info-card-content">{children}</div>
      <button className="edit-button" onClick={handleEditClicked}>
        Edit
      </button>
    </div>
  );
};

// TODO: Add Notes to "notesText" and Other to "otherText" in review and confirmation screen

// Review Screen
export default function ReviewScreen() {
  // const {personalInfo, franchise, selectedSlot, vehicleInfo, selectedServices, otherText, isDropoff, confirmationNumber, canCall, canText, canEmail, temporarySlotId, isAfterHoursDropoff} = useSelector(state => state.scheduler);
  const {
    personalInfo,
    franchise,
    selectedSlot,
    vehicleInfo,
    selectedServices,
    otherText,
    isDropoff,
    confirmationNumber,
    canCall,
    canText,
    canEmail,
    temporarySlotId,
    isAfterHoursDropoff,
    recommendedServices,
    dgbCustomerData,
    dgbVehicleId,
    isVehicleNew,
  } = useSelector((state) => state.scheduler);
  const urlSlug = useSelector((state) => state.scheduler.franchise.urlSlug);
  const { selectedShuttleService } = useSelector((state) => state.scheduler);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [canBook, setCanBook] = useState(true);
  const [canBook, setCanBook] = useState(true);

  // Called whenever we are terminating a session
  const wipeUserSession = () => {
    if (temporarySlotId) {
      dispatch(releaseTemporarySlot());
    }
    dispatch(clearSession());
    dispatch(setModalContents(null));
  };

  // Fires when book is click
  const handleGABookingClicked = () => {
    const contactPreferences = [];
    if (canCall) {
      contactPreferences.push("Phone");
    }
    if (canText) {
      contactPreferences.push("Text");
    }
    if (canEmail) {
      contactPreferences.push("Email");
    }

    // Map our index to our service ID
    const services = selectedServices.map((service) => {
      return franchise.services[service].text;
    });

    //! Hiding PII fields for analytics.
    const label = {
      appointmentDetails: {
        contactPreferences: contactPreferences,
        make: vehicleInfo.make,
        model: vehicleInfo.model,
        year: vehicleInfo.year,
        color: vehicleInfo.color,
        services: services,
        shuttleServiceSelected: selectedShuttleService,
        isFirstTimeVisiting: personalInfo.firstVisit === "Yes",
        notes: otherText,
      },
      franchiseeId: franchise.franchiseeId,
      slotId: temporarySlotId,
      date: selectedSlot.date,
      slot: selectedSlot.time,
    };

    const dataLayer = {
      event: "event_book_appointment",
      data: label,
    };

    TagManager.dataLayer({
      dataLayer,
    });
  };

  const recommendedServiceList = useMemo(() => {
    if (recommendedServices && dgbCustomerData && !isVehicleNew) {
      const vehicle = dgbCustomerData.garage?.find(
        (vehicle) => vehicle.garageId === dgbVehicleId
      );
      console.log(vehicle);
      const serviceMap = recommendedServices.map((id, index) => {
        console.log("service", id);
        const currentService = vehicle.recommendedHistory[0]?.jobs.find(
          (job) => job.id === id
        );
        console.log(currentService);
        return (
          <li key={`${currentService.id}`}>
            {currentService.name.split(":")[0]}
          </li>
        );
      });
      console.log(serviceMap);
      return serviceMap;
    }

    return <></>;
  }, [recommendedServices]);

  // Memo for service list
  const serviceList = useMemo(() => {
    if (selectedServices) {
      // console.log(selectedServices, franchise.services);
      const serviceMap = selectedServices.map((service, index) => {
        const currentService = franchise.services[service];
        return (
          <li key={`${currentService.serviceId}_${index}`}>
            {currentService.text}
          </li>
        );
      });

      if (otherText) {
        serviceMap.push(
          <li className="review-other-item" key={`other_service-Key`}>
            Notes: <span className="review-other-text">{otherText}</span>
          </li>
        );
      }

      return serviceMap;
    }

    return <></>;
  }, [selectedServices]);

  // Format our date for display
  const formattedDate = useMemo(() => {
    if (selectedSlot) {
      const formatted = dayjs(selectedSlot.date);
      return formatted.format("dddd, MMMM D, YYYY");
    }
  }, [selectedSlot]);

  // Format phone and alt phone numbers
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  // Shuttle Service
  const handleShuttleClicked = (e) => {
    e.preventDefault();

    // Return the value of our shuttle service flag
    const dataLayer = {
      event: "event_select_shuttle",
      shuttleServiceValue: !selectedShuttleService,
    };

    // Push to dataLayer
    TagManager.dataLayer({
      dataLayer,
    });

    dispatch(setSelectedShuttleService(!selectedShuttleService));
  };

  // Cancel booking when user clicks cancel in modal
  const cancelBooking = (e) => {
    e.preventDefault();

    // GTM
    const dataLayer = {
      event: "event_cancel_booking",
    };

    TagManager.dataLayer({
      dataLayer,
    });

    // Wipe our session
    wipeUserSession();

    // Redirect to franchise
    setTimeout(() => {
      window.location.href = `https:/www.cbac.com`;
    }, 0.1);
  };

  // Change user's location when clicked within modal
  const changeLocation = (e) => {
    e.preventDefault();

    // Wipe our session
    wipeUserSession();

    setTimeout(() => {
      window.location.href = `https://www.cbac.com/locations/`;
    }, 0.1);
  };

  const closeModal = (e) => {
    e.preventDefault();
    dispatch(setModalContents(null));
  };

  // Fired when user clicks the cancel button on the bottom nav
  const handleCancel = (e) => {
    e.preventDefault();
    const modal = (
      <CancelModal handleCancel={closeModal} handleContinue={cancelBooking} />
    );
    dispatch(setModalContents(modal));
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    // history.goBack();
    navigate(-1);
  };

  const handleLocationEdit = () => {
    // Edit Clicked
    const dataLayer = {
      event: "event_edit_location",
    };

    // Push to dataLayer
    TagManager.dataLayer({
      dataLayer,
    });

    const modal = (
      <LocationModal
        handleCancel={closeModal}
        handleContinue={changeLocation}
      />
    );
    dispatch(setModalContents(modal));
  };

  const handleBook = async (e) => {
    e.preventDefault();
    if (canBook) {
      handleGABookingClicked();
      dispatch(bookAppointment());
      setCanBook(false);
    }
  };

  // Function used to validate that we have all information that we need to book
  const checkCanBook = () => {
    return canBook;
  };

  const handleTimeEdit = (e) => {
    e.preventDefault();
    dispatch(setModalContents(null));
    dispatch(selectTimeSlot({ date: "", time: "" }));
    // history.push(`/${urlSlug}/date-time`);
    navigate(`${urlSlug}/date-time`);
  };

  const formattedTime = useMemo(() => {
    const unformattedTime = selectedSlot.time;
    // console.log(unformattedTime)

    // Convert our hours into the correct Label for 12 hour time
    const slotHours = Number(unformattedTime.substring(0, 2));
    const minutes = unformattedTime.split(":")[1];
    const hours = slotHours < 12 ? slotHours : ((slotHours + 11) % 12) + 1;
    const suffix = unformattedTime.substr(0, 2) > 11 ? "PM" : "AM";
    const label = `${hours}:${minutes} ${suffix}`;

    return label;
  }, [selectedSlot]);

  // Confirmation Modal Effect
  useEffect(() => {
    if (confirmationNumber) {
      // Direct us to confirmation page
      // history.push(`/${urlSlug}/confirmation`);
      navigate(`/${urlSlug}/confirmation`);
    }
  }, [confirmationNumber, personalInfo]);

  // Redirect if we are ever missing services
  useEffect(() => {
    if (!selectedServices.length && !recommendedServices.length && urlSlug) {
      // setTimeout(() => {
      dispatch(setModalContents(null));
      // }, 0);
      // history.push(`/${urlSlug}/`);
      navigate(`/${urlSlug}/`);
    }

    const timeModal = <TimeModal handleContinue={handleTimeEdit} />;

    // Make sure we have services for this
    if (selectedServices.length) {
      // If we have services, but no time, show time modal
      if (!selectedSlot.time || !selectedSlot.date) {
        dispatch(setModalContents(timeModal));
      }
    } else {
      dispatch(setModalContents(null));
    }
  }, [urlSlug, selectedServices, temporarySlotId, selectedSlot]);

  // Component Did Mount
  useEffect(() => {
    dispatch(setCurrentStep(5));
  }, [dispatch]);

  // Handler for after hours dropoff
  const handleAfterHoursClicked = (e) => {
    e.preventDefault();

    // Show our modal
    const modal = <AfterHoursModal />;

    dispatch(setModalContents(modal));
  };

  // Dropoff Text
  const getDropoffText = () => {
    if (isDropoff !== "Yes") {
      return "Stay and Wait";
    }

    if (isAfterHoursDropoff) {
      return (
        <p className="after-hours-label">
          After Hours Drop Off
          <button
            className="dropoff-info-button"
            onClick={handleAfterHoursClicked}
          >
            <IconInfo />
          </button>
        </p>
      );
    } else {
      // return 'Dropoff';
      return (
        <div>
          Dropoff:{" "}
          <small>
            Please note we will begin service at our first available opportunity
            after dropoff.
          </small>
        </div>
      );
    }
  };

  return (
    <div className="review-screen">
      <div className="review-container">
        <h1 className="heading booking-heading">{`Let's get you scheduled, ${personalInfo.firstName}!`}</h1>
        <p className="review-sentence please-review">
          {`Please review your details below and click "Confirm." We look forward
          to serving you.`}
        </p>
        <p className="review-sentence confirmation-number-row">
          Appointment ID# {confirmationNumber}
        </p>
        <div className="info-card-container">
          <InfoCard section="service" link={`/${urlSlug}/services`}>
            <div className="review-heading">
              <ul className="review-services">{serviceList}</ul>
              {!isVehicleNew ? (
                <ul className="review-services">{recommendedServiceList}</ul>
              ) : null}
            </div>
            {/*TODO: Add information text about pricing under the services list*/}
            <div className="review-text">{getDropoffText()}</div>
            {/*TODO: Janis wants to move this to under the date and time section*/}
          </InfoCard>
          <InfoCard
            className="review-section"
            section="date"
            link={`/${urlSlug}/date-time`}
          >
            <div className="review-heading">
              {formattedDate}{" "}
              {!isAfterHoursDropoff ? `at ${formattedTime}` : ""}
            </div>
          </InfoCard>
          <InfoCard section="store" handleEdit={handleLocationEdit}>
            <div className="review-heading">{franchise.franchiseName}</div>
            <div className="review-text">{franchise.street}</div>
            <div className="review-text">
              {franchise.city}, {franchise.state} {franchise.zipCode}
            </div>
            <a className="review-tel" href={`tel:${franchise.phone}`}>
              {franchise.phone}
            </a>
          </InfoCard>
          <InfoCard section="personal" link={`/${urlSlug}/personal-info`}>
            <div className="review-heading">
              {personalInfo.firstName} {personalInfo.lastName}
            </div>
            <div className="review-text">{personalInfo.email}</div>
            <div className="review-text">
              {formatPhoneNumber(personalInfo.phone)}
            </div>
          </InfoCard>
        </div>

        {/* Unfortunately, styling is in the DropoffScreen.css file. We are overriding it with review-shuttle-wrapper */}
        <div className="review-shuttle-wrapper">
          <div
            className={`dropoff-shuttle-container${
              isDropoff !== "Yes" || isAfterHoursDropoff ? " disabled" : ""
            }${selectedShuttleService ? " selected" : ""}`}
          >
            <div className="shuttle-left">
              <button
                id="shuttle-radio-button"
                className="shuttle-selector"
                role="switch"
                aria-checked={selectedShuttleService}
                disabled={isDropoff !== "Yes" || isAfterHoursDropoff}
                onClick={handleShuttleClicked}
              >
                {selectedShuttleService ? (
                  <IconRadioSelected />
                ) : (
                  <IconRadioEmpty />
                )}
              </button>
            </div>
            <div className="shuttle-right">
              <label
                htmlFor="shuttle-radio-button"
                className="shuttle-right-title"
              >
                Shuttle Service
              </label>
              {isDropoff !== "Yes" || isAfterHoursDropoff ? (
                <p className="shuttle-right-description">
                  Shuttle Service is unavailable for Stay & Wait or After Hours
                  Dropoff.
                </p>
              ) : (
                <span>
                  <p className="shuttle-right-description">
                    This location provides a complimentary shuttle service
                    during normal business hours.
                  </p>
                  {!selectedShuttleService ? (
                    <p className="shuttle-right-description">
                      Will you need a ride?
                    </p>
                  ) : null}
                </span>
              )}
            </div>
          </div>
        </div>

        <BottomNav
          enableContinue={checkCanBook}
          handleCancel={handlePrevious}
          handleContinue={handleBook}
          cancelLabel="Previous"
          continueLabel="Confirm"
        />
      </div>
    </div>
  );
}
