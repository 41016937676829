import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import DropoffIcon from "../components/DropoffIcon";
import {
  setIsDropoff,
  releaseTemporarySlot,
  selectTimeSlot,
  setSelectedShuttleService,
  setCurrentStep,
  setModalContents,
  clearSession,
  bookAppointment,
} from "../store/scheduler";
import "./DropoffScreen.css";

import { ReactComponent as IconRadioEmpty } from "../assets/icons/radio-check-empty.svg";
import { ReactComponent as IconRadioSelected } from "../assets/icons/radio-check-selected.svg";
import TagManager from "react-gtm-module";

import BottomNav from "../components/BottomNav";
import RedirectModal from "../components/modals/RedirectModal";
import DropOffConfirmationModal from "../components/modals/DropOffConfirmationModal";

export default function DropoffScreen() {
  // const {franchiseeName, urlSlug, services} = useSelector(state => state.scheduler.franchise);
  const { franchiseName, urlSlug, services } = useSelector(
    (state) => state.scheduler.franchise
  );
  const {
    selectedServices,
    isDropoff,
    temporarySlotId,
    selectedShuttleService,
    isAfterHoursDropoff,
    recommendedServices,
  } = useSelector((state) => state.scheduler);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const env = process.env.REACT_APP_ENV;

  // Redirect if we are ever missing services
  useEffect(() => {
    if (!selectedServices.length && !recommendedServices.length && urlSlug) {
      // history.replace(`/${urlSlug}/`);
      navigate(`/${urlSlug}`, { replace: true });
    }
  }, [urlSlug, selectedServices, recommendedServices]);

  useEffect(() => {
    dispatch(setCurrentStep(2));
  }, []);

  // Memo that returns whether or not we are stay and wait allowed
  const allowStayAndWait = useMemo(() => {
    // Return if we have no services
    if (!selectedServices.length && !recommendedServices.length) {
      return false;
    }

    let allowStayAndWait = true;

    for (let i = 0; i < selectedServices.length; i++) {
      const currentService = services[selectedServices[i]];

      // Dropoff does not have limited slots
      if (!currentService.hasLimitedSlots) {
        allowStayAndWait = false;
        break;
      }
    }

    if (recommendedServices.length) {
      allowStayAndWait = false;
    }

    return allowStayAndWait;
  }, [selectedServices, services]);

  // Builds our services sentence
  const getServicesString = () => {
    let servicesString = "";
    switch (recommendedServices.length) {
      case 0:
        servicesString = "";
        break;
      case 1:
        servicesString =
          selectedServices.length > 2 ? "" : "recommended service";
        break;
      default:
        servicesString =
          selectedServices.length > 2 ? "" : "recommended services";
    }

    // switch (selectedServices.length) {
    //   case 1:
    //     return `${services[selectedServices[0]].text}.`;
    //   case 2:
    //     return `${services[selectedServices[0]].text} and ${
    //       services[selectedServices[1]].text
    //     }.`;
    //   default:
    //     return "multiple services.";
    // }

    switch (selectedServices.length) {
      case 0:
        servicesString = servicesString + ".";
        break;
      case 1:
        servicesString =
          servicesString +
          (recommendedServices.length ? " and " : "") +
          `${services[selectedServices[0]].text}.`;
        break;
      case 2:
        servicesString =
          servicesString +
          (recommendedServices.length ? `, ` : "") +
          `${services[selectedServices[0]].text} and ${
            services[selectedServices[1]].text
          }.`;
        break;
      default:
        servicesString = servicesString + " multiple services.";
    }
    return servicesString;
  };

  // Toggle for Dropoff/StayAndWait
  const handleDropoffToggle = (isDropoff) => {
    const dropoffValue = isDropoff ? "Yes" : "No";

    // GTM EVENT for Dropoff/Stay And Wait
    const dataLayer = {
      event: isDropoff ? "event_dropoff_clicked" : "event_stayandwait_clicked",
    };

    // Push to dataLayer
    TagManager.dataLayer({
      dataLayer,
    });

    dispatch(setIsDropoff(dropoffValue));

    // Clear our time selection if we update services
    if (temporarySlotId) {
      // Free our temporary slot since we changed services
      dispatch(releaseTemporarySlot(temporarySlotId));
    }

    // Wipe our time with radio change
    dispatch(selectTimeSlot({ date: "", time: "" }));
  };

  // Effect to automatically select dropoff if we have no stay and wait options
  useEffect(() => {
    // Prevent automatically setting it 'Yes' every time
    if (!selectedServices.length && !recommendedServices.length) return;
    dispatch(setIsDropoff("Yes"));
  }, [selectedServices, allowStayAndWait]);

  // Shuttle Service
  const handleShuttleClicked = (e) => {
    e.preventDefault();

    // Return the value of our shuttle service flag
    const dataLayer = {
      event: "event_select_shuttle",
      shuttleServiceValue: !selectedShuttleService,
    };

    // Push to dataLayer
    TagManager.dataLayer({
      dataLayer,
    });

    dispatch(setSelectedShuttleService(!selectedShuttleService));
  };

  // Effect to deselect shuttle service if dropoff isn't selected
  useEffect(() => {
    if (isDropoff !== "Yes") {
      dispatch(setSelectedShuttleService(false));
    }
  }, [isDropoff]);

  const closeModal = (e) => {
    e.preventDefault();
    dispatch(setModalContents(null));
  };

  const handleRedirectClick = (e, url) => {
    e.preventDefault();

    if (env !== "TEST") {
      const modal = (
        <RedirectModal
          handleCancel={closeModal}
          handleContinue={() => {
            dispatch(releaseTemporarySlot());
            dispatch(clearSession());

            window.location.href = url;
          }}
        />
      );
      dispatch(setModalContents(modal));
    }
  };

  // Bottom nav
  const handleCancel = (e) => {
    e.preventDefault();
    // history.goBack();
    navigate(-1);
  };

  const handleContinue = (e) => {
    e.preventDefault();
    // TODO: Call confirmation modal to acknowledge drop off text and switch canBook to true

    if (process.env.REACT_APP_ENV !== "TEST" && isDropoff === "Yes") {
      const modal = (
        <DropOffConfirmationModal
          handleContinue={() => {
            navigate(`/${urlSlug}/date-time`);
            dispatch(setModalContents(null));
          }}
          handleCancel={() => closeModal(e)}
        />
      );
      dispatch(setModalContents(modal));
    } else {
      // history.push(`/${urlSlug}/date-time`);
      navigate(`/${urlSlug}/date-time`);
    }
  };

  return (
    <div className="dropoff-screen">
      {/*<h1 className="heading">I would like to visit the <a href="https://www.cbac.com/" className="landing-store">{franchiseeName}</a> store for <i>{getServicesString()}</i></h1>*/}
      {/*<h1 className="heading">I would like to visit the <a href="https://www.cbac.com/" className="landing-store">{franchiseName}</a> store for <i>{getServicesString()}</i></h1>*/}
      <h1 className="heading">
        I would like to visit the{" "}
        <a
          href={`https://www.cbac.com/${urlSlug}/`}
          className="landing-store"
          onClick={(e) =>
            handleRedirectClick(e, `https://www.cbac.com/${urlSlug}`)
          }
        >
          {franchiseName}
        </a>{" "}
        store for <i>{getServicesString()}</i>
      </h1>
      <p className="dropoff-instructions">
        {allowStayAndWait
          ? "Based on the services you selected, you can choose to wait while we take care of your vehicle."
          : "Based on the services you selected, we’ll need you to drop off your vehicle."}
      </p>

      <div className="dropoff-icons" data-testid="dropoff-icon">
        <DropoffIcon
          isDropoff={true}
          selected={isDropoff === "Yes"}
          handleClick={handleDropoffToggle}
        />
        <DropoffIcon
          isDropoff={false}
          selected={isDropoff === "No"}
          handleClick={handleDropoffToggle}
          disabled={!allowStayAndWait}
        />
      </div>

      <div
        className={`dropoff-shuttle-container${
          isDropoff !== "Yes" || isAfterHoursDropoff ? " disabled" : ""
        }${selectedShuttleService ? " selected" : ""}`}
      >
        <div className="shuttle-left">
          <button
            id="shuttle-radio-button"
            className="shuttle-selector"
            role="switch"
            aria-checked={selectedShuttleService}
            disabled={isDropoff !== "Yes" || isAfterHoursDropoff}
            onClick={handleShuttleClicked}
          >
            {selectedShuttleService ? (
              <IconRadioSelected />
            ) : (
              <IconRadioEmpty />
            )}
          </button>
        </div>
        <div className="shuttle-right">
          <label htmlFor="shuttle-radio-button" className="shuttle-right-title">
            Shuttle Service
          </label>
          {isDropoff !== "Yes" || isAfterHoursDropoff ? (
            <p className="shuttle-right-description">
              Shuttle Service is unavailable for Stay & Wait or After Hours
              Dropoff.
            </p>
          ) : (
            <span>
              <p className="shuttle-right-description">
                This location provides a complimentary shuttle service during
                normal business hours.
              </p>
              {!selectedShuttleService ? (
                <p className="shuttle-right-description">
                  Will you need a ride?
                </p>
              ) : null}
            </span>
          )}
          {/*<p className="shuttle-right-description">This location provides a complimentary shuttle service during normal business hours.</p>*/}
          {/*{!selectedShuttleService ? <p className="shuttle-right-description">Will you need a ride?</p>: null}*/}
        </div>
      </div>

      <BottomNav
        enableContinue={isDropoff}
        continueLabel="Continue"
        cancelLabel="Previous"
        handleCancel={handleCancel}
        handleContinue={handleContinue}
      />
    </div>
  );
}
