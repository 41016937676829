import "./RadioButton.css";

export default function RadioButton({
  label,
  name,
  value,
  checked,
  tabindex,
  onChange,
  disabled,
  ariaLabel,
}) {
  return (
    <div className="radio-button">
      {label}
      <label className="radio-button-container">
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked === value}
          tabIndex={tabindex}
          onChange={onChange}
          disabled={disabled}
          aria-label={ariaLabel}
        />
        <span
          className={`radio-button-checkmark${disabled ? " disabled" : ""}`}
        ></span>
      </label>
    </div>
  );
}
