import BottomNav from "../BottomNav.jsx";

export default function LocationModal({ handleCancel, handleContinue }) {
  return (
    <>
      <h1 className="heading">Are you sure you want to change locations?</h1>
      <p>
        If you change locations, any information you have provided will not be
        saved.
      </p>
      <BottomNav
        continueLabel="Yes, change."
        cancelLabel="No"
        handleCancel={handleCancel}
        handleContinue={handleContinue}
        enableContinue={true}
      />
    </>
  );
}
