import BottomNav from "../BottomNav.jsx";

export default function DropOffConfirmationModal({
  handleCancel,
  handleContinue,
}) {
  return (
    <>
      <h1 className="heading">Drop-Off Details</h1>
      <p>
        {`Please know that by selecting a Drop-Off appointment it does not guarantee that your vehicle's service will
         begin in this time slot.`}
        <br />{" "}
        <strong>
          Note: This is an arrival window and is not the actual service time.
        </strong>
      </p>
      <BottomNav
        continueLabel="Continue"
        cancelLabel="Back"
        handleCancel={handleCancel}
        handleContinue={handleContinue}
        enableContinue={true}
      />
    </>
  );
}
