// Breadcrumbs at the top of each screen

import { useEffect, useState } from "react";
import "./Breadcrumbs.css";

import { ReactComponent as IconTools } from "../assets/icons/icons-tools.svg";
import { ReactComponent as IconCalendar } from "../assets/icons/icons-calendar.svg";
import { ReactComponent as IconCar } from "../assets/icons/icons-car.svg";
import { ReactComponent as IconUser } from "../assets/icons/icons-user.svg";
import { ReactComponent as IconConfirmed } from "../assets/icons/icons-confirm.svg";
import { ReactComponent as IconCheck } from "../assets/icons/stepper-dot-complete.svg";
import { getFranchiseByName } from "../store/scheduler";
import { useSelector } from "react-redux";

export default function Breadcrumbs() {
  // Redux
  const step = useSelector((state) => state.scheduler.currentStep);

  // Array of all Icons
  const [icons, setIcons] = useState([
    "wrench",
    "calendar",
    "car",
    "person",
    "confirmed",
  ]);

  const currentStyle = {
    color: "#93d500",
  };

  const defaultStyle = {
    color: "#FFF",
  };

  // Return the SVG given the mapped name
  const getIcon = (iconName) => {
    switch (iconName) {
      case "car":
        return <IconCar style={step === 1 ? currentStyle : defaultStyle} />;
      case "wrench":
        return <IconTools style={step === 2 ? currentStyle : defaultStyle} />;
      case "calendar":
        return (
          <IconCalendar style={step === 3 ? currentStyle : defaultStyle} />
        );
      case "person":
        return <IconUser style={step === 4 ? currentStyle : defaultStyle} />;
      case "confirmed":
        return (
          <IconConfirmed style={step === 5 ? currentStyle : defaultStyle} />
        );
      case "checked":
      default:
        return <IconCheck />;
    }
  };

  // Did Mount
  useEffect(() => {
    // When we mount

    // 1. Check to see what our step is
    // 2. Change color of current step
    // 3. Update all previous step icons to checkboxes

    switch (step) {
      case 1:
        setIcons(["car", "wrench", "calendar", "person", "confirmed"]);
        break;
      case 2:
        setIcons(["checked", "wrench", "calendar", "person", "confirmed"]);
        break;
      case 3:
        setIcons(["checked", "checked", "calendar", "person", "confirmed"]);
        break;
      case 4:
        setIcons(["checked", "checked", "checked", "person", "confirmed"]);
        break;
      case 5:
        setIcons(["checked", "checked", "checked", "checked", "confirmed"]);
        break;
      default:
        setIcons(["car", "wrench", "calendar", "person", "confirmed"]);
    }
  }, [step]);

  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs-container">
        <div className="breadcrumbs-icon">{getIcon(icons[0])}</div>
        <div className="breadcrumbs-dash" />
        <div className="breadcrumbs-icon">{getIcon(icons[1])}</div>
        <div className="breadcrumbs-dash" />
        <div className="breadcrumbs-icon">{getIcon(icons[2])}</div>
        <div className="breadcrumbs-dash" />
        <div className="breadcrumbs-icon">{getIcon(icons[3])}</div>
        <div className="breadcrumbs-dash" />
        <div className="breadcrumbs-icon">{getIcon(icons[4])}</div>
      </div>
    </div>
  );
}
