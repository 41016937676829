import { useDispatch, useSelector } from "react-redux";

import FooterLogo from "../assets/logo-footer.png";
import Amex from "../assets/credit/amex.png";
import Discover from "../assets/credit/discover.png";
import Cash from "../assets/credit/cash.png";
import MasterCard from "../assets/credit/mastercard.png";
import Visa from "../assets/credit/visa.png";
import TagManager from "react-gtm-module";

import "./Footer.css";
import RedirectModal from "./modals/RedirectModal";
import {
  clearSession,
  releaseTemporarySlot,
  setModalContents,
} from "../store/scheduler";

export default function Footer() {
  const dispatch = useDispatch();
  const env = process.env.REACT_APP_ENV;

  // Redux
  const franchise = useSelector((state) => state.scheduler.franchise);
  const { socialLinks } = franchise;

  // Base URL for franchise links
  const baseUrl = "https://www.cbac.com/";
  const franchiseUrl = `https://www.cbac.com/${franchise.urlSlug}`;

  const FacebookIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
        className="ft-social-icon"
      >
        <g className="root" id="facebook" data-name="Facebook">
          <path d="M20.762 0C14.563 0 13.152 4.601 13.152 7.544L13.152 11.658L9.562 11.658L9.562 18.016L13.144 18.016C13.144 26.175 13.144 36 13.144 36L20.684 36C20.684 36 20.684 26.076 20.684 18.016L25.77 18.016L26.438 11.658L20.692 11.658L20.692 7.924C20.692 6.517 21.628 6.191 22.287 6.191L26.345 6.191L26.345 0.024L20.762 0Z" />
        </g>
      </svg>
    );
  };

  const TwitterIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
        className="ft-social-icon"
      >
        <g className="root" id="twitter" data-name="Twitter">
          <path d="M25.3 3.379C21.146 3.379 18.125 6.452 18.125 10.383C18.125 10.874 18.125 11.243 18.251 11.489L18.125 11.489C10.951 11.489 6.168 10.014 2.518 5.59C1.888 6.697 1.259 7.803 1.259 9.154C1.259 11.611 2.895 13.7 4.909 15.051L4.909 15.051C3.65 15.051 2.266 14.438 1.259 13.823L1.259 13.823C1.259 17.386 6.294 21.44 9.692 22.055C9.251 22.485 8.439 22.629 7.548 22.629C5.898 22.629 3.977 22.135 3.65 22.055L3.65 22.055C4.657 24.88 7.678 26.723 10.825 26.723C8.307 28.69 5.286 29.672 1.762 29.672C1.133 29.672 0.629 29.672 0 29.55L0 29.55C3.272 31.637 7.803 32.621 12.083 32.621C25.678 32.621 32.601 22.177 32.601 12.594C32.601 12.225 32.601 11.734 32.601 11.365C33.859 10.505 35.118 9.154 36 7.68L36 7.68C34.615 8.294 32.727 9.032 31.216 9.154C32.727 8.294 34.237 6.205 34.866 4.485L34.866 4.485C33.733 5.223 32.349 6.083 30.964 6.574L30.083 5.714C28.699 4.363 27.44 3.379 25.3 3.379Z" />
        </g>
      </svg>
    );
  };

  const GooglePlusIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
        className="ft-social-icon"
      >
        <g className="root" id="google_plus" data-name="Google Plus">
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M26.862 15.617c-1.177 1.036-2.73 1.666-4.43 1.666c-1.7 0-3.254-0.63-4.43-1.666c-1.177 1.036-2.73 1.666-4.43 1.666c-1.7 0-3.254-0.63-4.43-1.666c-1.177 1.036-2.73 1.666-4.43 1.666c-0.378 0-0.746-0.038-1.108-0.098v15.439C3.6 33.826 4.591 34.8 5.815 34.8h24.37C31.409 34.8 32.4 33.826 32.4 32.624V17.185c-0.361 0.06-0.73 0.098-1.108 0.098C29.592 17.284 28.038 16.652 26.862 15.617zM28.89 30.563c-1.372 1.724-3.902 2.231-5.963 1.554c-2.186-0.702-3.776-2.855-3.728-5.112c-0.109-2.788 2.389-5.364 5.244-5.398c1.454-0.121 2.87 0.431 3.972 1.332c-0.451 0.485-0.911 0.965-1.403 1.415c-0.968-0.576-2.136-1.014-3.268-0.625c-1.828 0.509-2.934 2.618-2.258 4.366c0.559 1.825 2.831 2.827 4.61 2.06c0.922-0.323 1.53-1.153 1.796-2.047c-1.056-0.02-2.113-0.008-3.168-0.036c-0.002-0.614-0.006-1.226-0.002-1.841c1.76-0.002 3.526-0.007 5.288 0.007C30.118 27.744 29.891 29.354 28.89 30.563zM31.2 1.2H4.8L0 9.988c0 2.045 1.438 3.749 3.374 4.238c0.361 0.091 0.734 0.155 1.126 0.155c2.485 0 4.5-1.967 4.5-4.394c0 2.426 2.015 4.394 4.5 4.394S18 12.415 18 9.988c0 2.426 2.015 4.394 4.5 4.394s4.5-1.967 4.5-4.394c0 2.426 2.015 4.394 4.5 4.394c0.391 0 0.763-0.064 1.126-0.155C34.562 13.736 36 12.034 36 9.988L31.2 1.2z"
          />
        </g>
      </svg>
    );
  };

  const LinkedinIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
        className="ft-social-icon"
      >
        <g className="root" id="linkedin" data-name="LinkedIn">
          <path d="M4.286 1.688C1.695 1.688 0 3.347 0 5.536C0 7.684 1.647 9.378 4.187 9.378L4.238 9.378C6.875 9.378 8.517 7.677 8.517 5.529C8.467 3.342 6.875 1.688 4.286 1.688ZM27.096 11.936C23.076 11.936 20.557 14.106 20.091 15.627L20.091 12.149L12.208 12.149C12.311 13.996 12.208 34.312 12.208 34.312L20.091 34.312L20.091 22.319C20.091 21.653 20.06 20.986 20.262 20.508C20.805 19.176 21.969 17.792 24.063 17.792C26.799 17.792 28.044 19.842 28.044 22.843L28.044 34.312L36 34.312L36 21.989C36 15.125 32.082 11.936 27.096 11.936ZM0.908 12.15L0.908 34.312L7.924 34.312L7.924 12.15L0.908 12.15Z" />
        </g>
      </svg>
    );
  };

  const YelpIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
        className="ft-social-icon"
      >
        <g className="root" id="yelp" data-name="Yelp">
          <path d="M4.353 24.667a1.628 1.628 0 0 0 1.371 0.889H5.815A45.635 45.635-0.063 0 0 11.736 23.791l2.412-0.752a1.779 1.779 0 0 0 1.311-1.688A1.853 1.853-0.063 0 0 14.299 19.421l-1.702-0.679h0C6.448 16.408 6.416 16.408 6.068 16.331a1.628 1.628 0 0 0-1.508 0.784a12.495 12.495 0 0 0-0.679 5.938A4.701 4.701-0.063 0 0 4.353 24.667Zm12.885-0.074a1.944 1.944 0 0 0-2.201 0.496l-1.086 1.266l-0.105 0.12C9.552 31.357 9.535 31.48 9.415 31.737a1.403 1.403 0 0 0-0.091 0.664a1.659 1.659 0 0 0 0.408 0.904A13.789 13.789-0.063 0 0 16.651 36.002l0.376 0a1.614 1.614 0 0 0 1.297-1.009c0.12-0.316 0.12-0.348 0.137-6.117 0 0 0-2.398 0-2.472A1.765 1.765-0.063 0 0 17.238 24.575ZM17.628 1.202v0a1.628 1.628 0 0 0-1.252-1.132C14.689-0.362 8.526 1.34 7.334 2.514a1.596 1.596 0 0 0-0.454 1.596c0.179 0.362 6.887 10.656 7.657 11.817s1.403 1.614 2.169 1.614a1.747 1.747 0 0 0 0.513-0.074c0.963-0.285 1.403-1.206 1.325-2.728C18.458 12.354 17.719 1.73 17.628 1.202Zm4.205 19.277a2.05 2.05 0 0 1-1.206-0.858a1.719 1.719 0 0 1 0-2.109l1.508-1.99c3.435-4.521 3.512-4.687 3.815-4.869a1.642 1.642 0 0 1 1.642 0a12.66 12.66 0 0 1 4.324 6.209v0.091a1.508 1.508 0 0 1-0.679 1.508c-0.302 0.179-0.362 0.225-6.768 1.733A9.225 9.225-0.063 0 1 21.833 20.462ZM31.586 25.721c-0.271-0.197-0.302-0.211-5.938-2.018l-2.398-0.784a1.853 1.853 0 0 0-2.064 0.619A1.825 1.825-0.063 0 0 21.035 25.735l0.963 1.508c3.361 5.305 3.498 5.516 3.783 5.727a1.508 1.508 0 0 0 0.935 0.316a1.899 1.899 0 0 0 0.693-0.137a12.646 12.646 0 0 0 4.792-5.938A1.508 1.508-0.063 0 0 31.586 25.721Z" />
        </g>
      </svg>
    );
  };

  const handleGAPhoneEvent = () => {
    const dataLayer = {
      event: "event_phone_clicked",
      franchiseNumber: franchise.phone,
    };

    TagManager.dataLayer({
      dataLayer,
    });
  };

  const closeModal = (e) => {
    e.preventDefault();
    dispatch(setModalContents(null));
  };

  const handleRedirectClick = (e, url) => {
    e.preventDefault();

    if (env !== "TEST") {
      const modal = (
        <RedirectModal
          handleCancel={closeModal}
          handleContinue={() => {
            dispatch(releaseTemporarySlot());
            dispatch(clearSession());

            window.location.href = url;
          }}
        />
      );
      dispatch(setModalContents(modal));
    }
  };
  
  return (
    <footer>
      <div className="ft-flex ft-top">
        <div>
          <img
            className="ft-bottom-logo"
            alt="Christian Brothers Automotive"
            title="Christian Brothers Automotive"
            src={FooterLogo}
          />

          <nav>
            {/* Footer Nav Line 1 */}
            <ul>
              <li>
                <a
                  href={`${franchiseUrl}/our-services`}
                  onClick={(e) =>
                    handleRedirectClick(e, `${franchiseUrl}/our-services`)
                  }
                >
                  Our Services
                </a>
              </li>
              <li>
                <a
                  href={`${franchiseUrl}/about-us/why-choose-us/`}
                  onClick={(e) =>
                    handleRedirectClick(
                      e,
                      `${franchiseUrl}/about-us/why-choose-us/`
                    )
                  }
                >
                  Why Choose Us?
                </a>
              </li>
              <li>
                <a
                  href={env !== "TEST" ? "https://www.cbautojobs.com/" : null}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Careers
                </a>
              </li>
              <li>
                <a
                  href={
                    env !== "TEST"
                      ? "https://www.christianbrothersfranchise.com/"
                      : null
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Buy A Franchise
                </a>
              </li>
              <li>
                <a
                  href={`${franchiseUrl}/media-center/blog/`}
                  onClick={(e) =>
                    handleRedirectClick(e, `${franchiseUrl}/media-center/blog/`)
                  }
                >
                  In The News
                </a>
              </li>
            </ul>
            {/* Footer Nav Line 2 */}
            <ul>
              <li>
                <a
                  href={`${franchiseUrl}/contact-us/`}
                  onClick={(e) =>
                    handleRedirectClick(e, `${franchiseUrl}/contact-us/`)
                  }
                >
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  href="https://www.cbac.com/site-map/"
                  onClick={(e) =>
                    handleRedirectClick(e, `${baseUrl}/site-map/`)
                  }
                >
                  Site Map
                </a>
              </li>
              <li>
                <a
                  href="https://www.cbac.com/terms-of-use/"
                  onClick={(e) =>
                    handleRedirectClick(e, `${baseUrl}/terms-of-use/`)
                  }
                >
                  Terms of Use
                </a>
              </li>
              <li>
                <a
                  href="https://www.cbac.com/privacy-policy/"
                  onClick={(e) =>
                    handleRedirectClick(e, `${baseUrl}/privacy-policy/`)
                  }
                >
                  Privacy Policy
                </a>
              </li>
              <li style={{ paddingBottom: "10px" }}>
                <a
                  href="#"
                  onClick={() =>
                    Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
                  }
                >
                  Your Privacy Options
                  <img
                    src="https://www.cbac.com/images/privacyoptions-white.svg"
                    alt="CCPA Opt-Out"
                    style={{ width: "30px", height: "30px", paddingTop: "18px", paddingLeft: "4px"}}
                  />
                </a>
              </li>
            </ul>
          </nav>
        </div>
        {/* Right Column */}
        <div className="ft-right">
          <div className="ft-contact-info">
            <a
              className="ft-phone"
              onClick={handleGAPhoneEvent}
              href={
                env !== "TEST" ? `tel:${franchise && franchise.phone}` : null
              }
            >
              {franchise && franchise.phone}
            </a>
            {/* <a className="btn-footer btn-location" href="https://www.cbac.com/locations/" target="_blank" rel="noopener noreferrer"><LocationIcon/> Change Location</a> */}
          </div>
          <div className="social-container">
            {/*{socialLinks && socialLinks.facebook && <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer"><FacebookIcon/></a>}*/}
            {/*{socialLinks && socialLinks.twitter && <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer"><TwitterIcon/></a>}*/}
            {/*{socialLinks && socialLinks.googlemaps && <a href={socialLinks.googlemaps} target="_blank" rel="noopener noreferrer"><GooglePlusIcon /></a>}*/}
            {/*{socialLinks && socialLinks.linkedin && <a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer"><LinkedinIcon /></a>}*/}
            {/*{socialLinks && socialLinks.yelp && <a href={socialLinks.yelp} target="_blank" rel="noopener noreferrer"><YelpIcon /></a>}*/}
          </div>
        </div>
      </div>
      <div className="ft-flex ft-bottom">
        <div className="ft-payment-row">
          <p>We accept the following forms of payment:</p>
          <ul>
            <li>
              <img src={Visa} alt="Visa" />
            </li>
            <li>
              <img src={Amex} alt="American Express" />
            </li>
            <li>
              <img src={MasterCard} alt="Master Card" />
            </li>
            <li>
              <img src={Discover} alt="Discover" />
            </li>
            <li>
              <img src={Cash} alt="Cash" />
            </li>
          </ul>
        </div>
        <div className="ft-disclaimer">
          <p>Auto Repair And Maintenance Shop</p>
          <small>{`© ${new Date().getFullYear()} | All Rights Reserved | All Christian Brothers Automotive franchise locations are independently owned and operated.`}</small>
        </div>
      </div>
    </footer>
  );
}
