// Individual Date Picker dropdown shown on calendar and week view
import { useMemo, useState } from "react";
import { ReactComponent as IconArrowDown } from "../assets/icons/icons-arrow-down.svg";
import { ReactComponent as IconArrowUp } from "../assets/icons/icons-arrow-up.svg";

import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  releaseTemporarySlot,
  selectTimeSlot,
  setCurrentlyOpenDateDropdown,
  setIsAfterHoursDropoff,
  setModalContents,
  setSelectedShuttleService,
} from "../store/scheduler";
import AfterHoursModal from "./modals/AfterHoursModal";
import TagManager from "react-gtm-module";

import "./DatePicker.css";

//=======================================================
// Button within date picker that contains a specific time
//=======================================================

const DateButton = ({
  label,
  handleSelected,
  inactive,
  time,
  date,
  dropoffDate,
  selected,
}) => {
  // Click handler for our button
  const handleClicked = (e) => {
    e.preventDefault();

    if (handleSelected) {
      // Return the current time that we selected here
      handleSelected(time, date, dropoffDate);
    } else {
      console.warn("You need to pass in a handler for button clicks");
    }
  };

  return (
    <button
      onClick={handleClicked}
      className={`datepicker-btn ${selected ? "selected" : ""}`}
      disabled={inactive}
      aria-label={"time slot"}
    >
      {label}
    </button>
  );
};

//=======================================================
// Date Picker component exported and used by DateTime view
//=======================================================

export default function DatePicker({ data, alwaysOpen }) {
  const dispatch = useDispatch();
  const { breakStartTime } = useSelector((state) => state.scheduler.franchise);
  const selectedSlot = useSelector((state) => state.scheduler.selectedSlot);
  const temporarySlotId = useSelector(
    (state) => state.scheduler.temporarySlotId
  );
  const {
    currentlyOpenDateDropdown,
    isDropoff,
    isAfterHoursDropoff,
    selectedShuttleService,
  } = useSelector((state) => state.scheduler);
  const menuIsOpen = currentlyOpenDateDropdown === data.key;

  // User Clicked on a Date Button
  const handlePickedDate = (time) => {
    if (temporarySlotId) {
      dispatch(releaseTemporarySlot());
    }

    // Clear after hours dropoff if selected
    if (isAfterHoursDropoff) {
      dispatch(setIsAfterHoursDropoff(false));
    }

    dispatch(selectTimeSlot({ date: data.key, time, dropoffDate: null }));
  };

  // Callback for after hours button
  const handleAfterHoursClicked = (time, date, dropoffDate) => {
    if (temporarySlotId) {
      dispatch(releaseTemporarySlot());
    }

    dispatch(setIsAfterHoursDropoff(true));

    // Dispatch our slots date (day of dropoff), time (time for scheduler), and dropoffDate(day for scheduler)
    dispatch(selectTimeSlot({ date, time, dropoffDate }));

    // Check to make sure we have a shuttle service before firing this event
    if (selectedShuttleService) {
      // GTM - Deselect shuttle service automatically
      const dataLayer = {
        event: "event_select_shuttle",
        shuttleServiceValue: false,
      };

      // Push to dataLayer
      TagManager.dataLayer({
        dataLayer,
      });
    }

    // Clear our shuttle service since it isn't allowed
    dispatch(setSelectedShuttleService(false));

    // Show our modal
    const modal = <AfterHoursModal />;

    dispatch(setModalContents(modal));
  };

  // User Clicked on a Dropdown Button
  const handleOpenMenu = (e) => {
    e.preventDefault();

    if (menuIsOpen) {
      dispatch(setCurrentlyOpenDateDropdown(""));
    } else {
      dispatch(setCurrentlyOpenDateDropdown(data.key));
    }
  };

  // Store our day.js date
  const date = dayjs(data.key);
  const today = dayjs();

  // Time difference from our current day
  const isToday = date.format("DDMMYYYY") === today.format("DDMMYYYY");
  const displayDay = date.format("ddd").toUpperCase();
  const displayDate = date.format("MMM D");
  const locationClosed = !data.slots.length;

  // Check to see if after hours dropoff is allowed
  const afterHours =
    data.afterHoursDropoff && data.afterHoursDropoff.isAfterHoursDropoff;

  // Determine if we show carot button
  const showCarot = useMemo(() => {
    // No carot on calendar
    if (alwaysOpen) return false;

    // No carot on location closed without after hours
    if (locationClosed && !afterHours) return false;

    return true;
  }, [alwaysOpen, locationClosed, afterHours]);

  // Generate our date buttons
  const generateDateButtons = useMemo(() => {
    // Filter out break time slots
    return data.slots
      .filter(
        (item) => item.fromTime.split(":")[0] !== breakStartTime.split(":")[0]
      )
      .map((slot, index) => {
        // Convert our hours into the correct Label for 12 hour time
        const slotHours = Number(slot.fromTime.substring(0, 2));
        const minutes = slot.fromTime.split(":")[1];
        const hours = slotHours < 12 ? slotHours : ((slotHours + 11) % 12) + 1;
        const suffix = slot.fromTime.substr(0, 2) > 11 ? "PM" : "AM";
        // const label = `${hours}:00 ${suffix}`;
        const label = `${hours}:${minutes} ${suffix}`;

        return (
          <DateButton
            label={label}
            selected={
              selectedSlot &&
              selectedSlot.date === data.key &&
              selectedSlot.time === slot.fromTime &&
              !selectedSlot.dropoffDate
            }
            key={`${slot.fromTime}_${index}`}
            time={slot.fromTime}
            inactive={slot.availableSlots <= 0 || locationClosed}
            handleSelected={handlePickedDate}
          />
        );
      });
  }, [isToday, locationClosed, today, selectedSlot, data]);

  // Returns our after hours button
  const getAfterHoursButton = () => {
    // No button if stay and wait
    if (!isDropoff) return;
    if (!data.afterHoursDropoff) return;

    return (
      <DateButton
        label="After Hours"
        selected={
          selectedSlot &&
          selectedSlot.date === data.key &&
          selectedSlot.time === data.afterHoursDropoff.fromTime &&
          selectedSlot.dropoffDate
        }
        time={data.afterHoursDropoff.fromTime}
        date={data.key}
        dropoffDate={data.afterHoursDropoff.afterHoursDropoffDate}
        inactive={!data.afterHoursDropoff.isAfterHoursDropoff}
        handleSelected={handleAfterHoursClicked}
      />
    );
  };

  // Returns arrow depending on selection made
  const getToggleIcon = useMemo(() => {
    return menuIsOpen ? <IconArrowUp /> : <IconArrowDown />;
  }, [menuIsOpen]);

  // Calculate our display label for the current day
  const getDisplayLabel = () => {
    // Default labels
    if (locationClosed && !afterHours) {
      return data.displayLabel === "Location Closed"
        ? data.displayLabel
        : `Location Closed - ${data.displayLabel}`;
    }

    if (locationClosed && data.displayLabel !== "0 available times") {
      return `Location Closed - ${data.displayLabel}`;
    }

    if (afterHours && !locationClosed && data.availableTimes === 0) {
      return "After Hours Drop Off Only";
    }

    if (!locationClosed) return data.displayLabel;

    // After hours dropoff label modifications
    return "Closed, After Hours Drop Off Only";
  };

  return (
    <div className="datepicker">
      <div
        className="datepicker-head"
        onClick={showCarot ? handleOpenMenu : undefined}
        onKeyDown={showCarot ? handleOpenMenu : undefined}
        style={{ cursor: showCarot ? "pointer" : "default" }}
        role={"presentation"}
      >
        <h2>{displayDay}</h2>
        <p className="datepicker-date">{isToday ? "Today" : displayDate}</p>
        <p
          className={`datepicker-times-available ${
            locationClosed && !afterHours ? "unavailable" : ""
          }`}
        >
          {getDisplayLabel()}
        </p>
        {showCarot && <div className="toggle-icon">{getToggleIcon}</div>}
      </div>

      {menuIsOpen && (
        <div className="datepicker-button-container">
          {generateDateButtons}
          {getAfterHoursButton()}
        </div>
      )}
    </div>
  );
}
