import { ReactComponent as IconAfterHours } from "./after-hours.svg";
import { ReactComponent as IconAirConditioning } from "./air-conditioning-and-heating.svg";
import { ReactComponent as IconAlignment } from "./alignment-check.svg";
import { ReactComponent as IconBattery } from "./battery-and-electrical.svg";
import { ReactComponent as IconBrakes } from "./brakes.svg";
import { ReactComponent as IconCarePlusInspection } from "./carePlusInspection.svg";
import { ReactComponent as IconCheckEngine } from "./check-engine-light.svg";
import { ReactComponent as IconEngineTrouble } from "./engine-trouble.svg";
import { ReactComponent as IconFluidService } from "./fluid-service.svg";
import { ReactComponent as IconLeak } from "./leak.svg";
import { ReactComponent as IconNoises } from "./noises.svg";
import { ReactComponent as IconOilChange } from "./oil-change.svg";
import { ReactComponent as IconOther } from "./other.svg";
import { ReactComponent as IconPrePurchaseInspection } from "./pre-purchase-inspection.svg";
import { ReactComponent as IconScheduledMaintenance } from "./scheduled-maintenance.svg";
import { ReactComponent as IconStartingTrouble } from "./starting-trouble.svg";
import { ReactComponent as IconStateInspection } from "./state-inspection.svg";
import { ReactComponent as IconSteering } from "./steering-and-suspension.svg";
import { ReactComponent as IconTireRotation } from "./tire-rotation.svg";
import { ReactComponent as IconTransmission } from "./transmission.svg";
import { ReactComponent as IconVibration } from "./vibration.svg";

const serviceIcons = {
  afterHours: IconAfterHours,
  airConditioningAndHeating: IconAirConditioning,
  alignmentCheck: IconAlignment,
  batteryAndElectrical: IconBattery,
  brakes: IconBrakes,
  carePlusInspection: IconCarePlusInspection,
  checkEngineLight: IconCheckEngine,
  engineTrouble: IconEngineTrouble,
  fluidService: IconFluidService,
  leak: IconLeak,
  noise: IconNoises,
  oilChange: IconOilChange,
  other: IconOther,
  prePurchaseInspection: IconPrePurchaseInspection,
  scheduledMaintenance: IconScheduledMaintenance,
  startingTrouble: IconStartingTrouble,
  stateInspection: IconStateInspection,
  steeringAndSuspension: IconSteering,
  tireRotation: IconTireRotation,
  transmission: IconTransmission,
  vibration: IconVibration,
};

export { serviceIcons };
