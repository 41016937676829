import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { cancelAppointment } from "../store/scheduler.js";

import "./CancellationScreen.css";

export default function CancellationScreen() {
  const { cancellationId } = useParams();
  const { cancellationSuccess } = useSelector((state) => state.scheduler);
  const dispatch = useDispatch();

  useEffect(() => {
    if (cancellationId) {
      // console.log(cancellationId);
      dispatch(cancelAppointment(cancellationId));
    }
  }, [cancellationId]);

  return (
    <div className="cancellation">
      <h1 className="heading">{`Your appointment has ${
        !cancellationSuccess ? "already" : ""
      } been canceled!`}</h1>
    </div>
  );
}
