import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retry } from "@reduxjs/toolkit/query";
// import { setWidgetLoaded } from "../../features/profile/profileSlice.js";

export const StoreLocatorWidget = () => {
  const dispatch = useDispatch();
  const carePlusReferrer = useSelector(
    (state) => state.scheduler.carePlusReferrer
  );

  useEffect(() => {
    (function () {
      var a = document.createElement("script");
      a.type = "text/javascript";
      a.async = false;
      a.id = "sp-script";
      a.src = "https://cdn.storepoint.co/api/v1/js/16596fe55063d4.js";
      // a.onload = function () {
      //     dispatch(setWidgetLoaded(true));
      // };
      document.body.appendChild(a);
      // var b = document.getElementsByTagName("script")[0];
      // b.parentNode.insertBefore(a, b);
    })();
  }, []);

  return carePlusReferrer === "careplus" ? (
    <div
      id="storepoint-container"
      data-tags="Care+"
      data-map-id="16596fe55063d4"
    ></div>
  ) : (
    <div id="storepoint-container" data-map-id="16596fe55063d4"></div>
  );
  // return <div id="storepoint-container" data-map-id="16596fe55063d4"></div>;
  // return <div id="storepoint-container" data-map-id="16596fe55063d4"></div>;
  // return (
  //   <div
  //     id="storepoint-container"
  //     data-tags="warranty"
  //     data-map-id="16596fe55063d4"
  //   ></div>
  // );
};
