import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BottomNav from "../BottomNav.jsx";
import { setModalContents, selectTimeSlot } from "../../store/scheduler.js";

export default function TimeRanOutModal({ handleCancel }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlSlug = useSelector((state) => state.scheduler.franchise.urlSlug);

  // Handle when user clicks 'edit time'
  const handleTimeEdit = (e) => {
    e.preventDefault();
    dispatch(setModalContents(null));
    dispatch(selectTimeSlot({ date: "", time: "" }));
    // history.push(`/${urlSlug}/date-time`);
    navigate(`/${urlSlug}/date-time`);
  };

  return (
    <>
      <h1 style={{ marginBottom: 20 }} className="heading time-modal-head">
        Unfortunately, the time that you selected is invalid.
      </h1>
      <p style={{ marginBottom: 10 }}>
        Please select a valid time to continue.
      </p>
      <BottomNav
        continueLabel="Select Time"
        cancelLabel="No"
        handleCancel={handleCancel}
        handleContinue={handleTimeEdit}
        enableContinue={true}
        hideCancel
      />
    </>
  );
}
