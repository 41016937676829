// Initial landing page view for the application (step 1)
// View contains services that the user should pick from a dropdown
// If the user does not have a location, this view falls back to present user with location picker

import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BottomNav from "../components/BottomNav.jsx";
import ServiceIcon from "../components/ServiceIcon.jsx";
import { ReactComponent as IconArrowDown } from "../assets/icons/icons-arrow-down.svg";
import { ReactComponent as IconArrowUp } from "../assets/icons/icons-arrow-up.svg";
import { ReactComponent as IconInfo } from "../assets/icons/icons-info.svg";
import TagManager from "react-gtm-module";

import ServicesModal from "../components/modals/ServicesModal.jsx";
import {
  clearSession,
  releaseTemporarySlot,
  selectTimeSlot,
  setCurrentStep,
  setModalContents,
  setOtherText,
  setRecommendedServices,
  setSelectedServices,
} from "../store/scheduler";
import { useLocation, useNavigate } from "react-router-dom";

import "../assets/semantic-ui.css";
import "./LandingScreen.css";
import RedirectModal from "../components/modals/RedirectModal";
import { useIsAuthenticated } from "@azure/msal-react";
import { ReactComponent as IconRadioEmpty } from "../assets/icons/radio-check-empty.svg";
import { ReactComponent as IconRadioSelected } from "../assets/icons/radio-check-selected.svg";

export default function LandingScreen() {
  // Redux
  // const {franchiseeName, services, urlSlug} = useSelector(state => state.scheduler.franchise);
  const { franchiseName, services, urlSlug } = useSelector(
    (state) => state.scheduler.franchise
  );
  // const {franchiseName, urlSlug} = useSelector(state => state.scheduler.franchise);
  const {
    selectedServices,
    temporarySlotId,
    otherText,
    isDropoff,
    dgbCustomerData,
    dgbVehicleId,
    recommendedServices,
    isVehicleNew,
    vehicleInfo,
  } = useSelector((state) => state.scheduler);
  const dispatch = useDispatch();
  const location = useLocation();
  const [recommendedServicesOpen, setRecommendedServicesOpen] = useState(true);
  const [commonServicesOpen, setCommonServicesOpen] = useState(true);
  const [somethingWrongServicesOpen, setSomethingWrongServicesOpen] =
    useState(true);

  const isAuthenticated = useIsAuthenticated();
  const [selectedRecommendedServices, setSelectedRecommendedServices] =
    useState(recommendedServices || []);

  // If true, display error around other state
  const [otherError, setOtherError] = useState(false);

  const navigate = useNavigate();
  const env = process.env.REACT_APP_ENV;

  /**
   * Add/Remove a given service index from our selected services
   * @param {number} value - Index of the service ID
   * @param {boolean} remove - If true, we are removing from our array
   */
  const handleServiceChange = (value, remove) => {
    const currentServices = selectedServices;
    let updatedServices;

    if (remove) {
      updatedServices = currentServices.filter((service) => service !== value);
    } else {
      updatedServices = [...currentServices, value];
    }

    // Select our services
    dispatch(setSelectedServices([...updatedServices]));

    // Clear our time selection if we update services
    if (temporarySlotId) {
      // Free our temporary slot since we changed services
      dispatch(releaseTemporarySlot(temporarySlotId));
    }

    // Wipe our calendar slot
    dispatch(selectTimeSlot({ date: "", time: "" }));

    // Check to see if we selected 'other'
    const currentService = services[value];
    if (currentService && currentService.isOther) {
      // If we are adding, show error + require
      // If we are removing, get rid of error
      if (!remove) {
        setOtherError(true);
      } else {
        setOtherError(false);
      }
    }
  };

  // Build our options - We pass the index when the option is selected
  const getOptions = useMemo(() => {
    if (services) {
      return services.map((service, index) => {
        return {
          key: service.serviceId,
          text: service.text,
          description: service.description,
          stayAndWait: service.hasLimitedSlots,
          icon: service.serviceIconId,
          category: service.categoryType,
          value: index,
        };
      });
    }

    return [];
  }, [services]);

  // Used to Automatically toggle error state for 'other' field
  useEffect(() => {
    if (!services) return;

    const otherServiceIndex = services.findIndex(
      (service) => service.text === "Other"
    );
    const otherSelected = selectedServices.includes(otherServiceIndex);

    // // Remove error if we have other
    if (otherSelected) {
      if (otherText) {
        setOtherError(false);
      } else {
        setOtherError(true);
      }
    } else {
      setOtherError(false);
    }
  }, [selectedServices, services, otherText]);

  // Redirect if we are ever missing services
  useEffect(() => {
    // if (!selectedServices.length && urlSlug) {
    if (!vehicleInfo.year && urlSlug) {
      // history.push(`/${urlSlug}/`);
      navigate(`/${urlSlug}/`);
    }
  }, [urlSlug, vehicleInfo, navigate]);

  // COMPONENT DID MOUNT
  useEffect(() => {
    dispatch(setCurrentStep(2));
    // console.log(location.state?.referrer?.pathname, urlSlug);
    if (location.state?.referrer?.pathname === `/${urlSlug}`) {
      dispatch(setRecommendedServices([]));
    }
  }, []);

  const closeModal = (e) => {
    e.preventDefault();
    dispatch(setModalContents(null));
  };

  const handleRedirectClick = (e, url) => {
    e.preventDefault();

    if (env !== "TEST") {
      const modal = (
        <RedirectModal
          handleCancel={closeModal}
          handleContinue={() => {
            dispatch(releaseTemporarySlot());
            dispatch(clearSession());

            window.location.href = url;
          }}
        />
      );
      dispatch(setModalContents(modal));
    }
  };

  // Cancel callback function
  const handleCancel = (e) => {
    e.preventDefault();
    // history.goBack();
    navigate(-1);
  };

  const handleContinue = () => {
    // Get our display strings for our selected services
    const serviceStrings = selectedServices.map((serviceIndex) => {
      return services[serviceIndex].text;
    });

    // GTM
    const dataLayer = {
      event: "event_selected_services",
      services: JSON.stringify(serviceStrings),
      otherText,
    };

    // Push to datalayer
    TagManager.dataLayer({
      dataLayer,
    });

    // history.push(`/${urlSlug}/dropoff`);
    navigate(`/${urlSlug}/dropoff`);
  };

  // Called whenever we update the textarea field
  const handleAdditionalDetails = (e) => {
    dispatch(
      setOtherText(
        e.target.value
          .replace(
            /(?![*#0-9]+)[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu,
            ""
          )
          .replace(/\s+/g, " ")
      )
    );
  };

  // Map the recommended services if logged in
  const getRecommendedServices =
    isAuthenticated &&
    dgbVehicleId &&
    !isVehicleNew &&
    Object.keys(dgbCustomerData).length !== 0 &&
    dgbCustomerData.garage
      .find((vehicle) => vehicle.garageId === dgbVehicleId)
      ?.recommendedHistory[0]?.jobs.map((service, index) => (
        <div key={index}>
          <div
            style={{
              display: "flex",
            }}
          >
            <button
              className="shuttle-selector"
              onClick={(e) => {
                e.preventDefault();
                handleRecommendedServiceChecked(service.id);
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "6px",
                  alignItems: "center",
                }}
              >
                <div style={{ color: "var(--brand-persimmon)" }}>
                  {selectedRecommendedServices.includes(service.id) ? (
                    <IconRadioSelected />
                  ) : (
                    <IconRadioEmpty />
                  )}
                </div>
                <p className={"recommended-service-text"}>
                  {service.name.split(":")[0]}
                </p>
              </div>
            </button>
          </div>
        </div>
      ));

  // Map our common services
  const commonServices = useMemo(() => {
    if (!getOptions) return null;

    // Filter for Common Services category
    const filteredServices = getOptions.filter(
      (service) => service.category === "Common Services"
    );

    // Filter out services missing text, icon, description, or key
    return filteredServices.map((service, index) =>
      service.icon && service.text && service.description && service.key ? (
        <ServiceIcon
          key={`${service.serviceId}_${index}`}
          selected={selectedServices.includes(service.value)}
          service={service}
          handleClick={handleServiceChange}
        />
      ) : null
    );
  }, [getOptions, selectedServices]);

  // Map our something's wrong services
  const somethingWrongServices = useMemo(() => {
    if (!getOptions) return null;

    // Filter for Something's Wrong category
    // const filteredServices = getOptions.filter(service => service.category === "Something’s Wrong");
    const filteredServices = getOptions.filter(
      (service) => service.category === "Something's Wrong"
    );

    // Filter out services missing text, icon, description, or key
    return filteredServices.map((service, index) =>
      service.icon && service.text && service.description && service.key ? (
        <ServiceIcon
          key={`${service.serviceId}_${index}`}
          selected={selectedServices.includes(service.value)}
          service={service}
          handleClick={handleServiceChange}
        />
      ) : null
    );
  }, [getOptions, selectedServices]);

  // Returns arrow depending on selection made
  const getToggleIconRecommended = useMemo(() => {
    return recommendedServicesOpen ? <IconArrowUp /> : <IconArrowDown />;
  }, [recommendedServicesOpen]);

  // Returns arrow depending on selection made
  const getToggleIcon = useMemo(() => {
    return somethingWrongServicesOpen ? <IconArrowUp /> : <IconArrowDown />;
  }, [somethingWrongServicesOpen]);

  // Returns arrow depending on selection made
  const getToggleIconCommon = useMemo(() => {
    return commonServicesOpen ? <IconArrowUp /> : <IconArrowDown />;
  }, [commonServicesOpen]);

  const handleCommonInfoClicked = (e) => {
    e.preventDefault();

    const filteredServices = getOptions.filter(
      (service) => service.category === "Common Services"
    );
    const modal = (
      <ServicesModal title="Common Services" services={filteredServices} />
    );

    dispatch(setModalContents(modal));
  };

  const handleSomethingWrongInfoClicked = (e) => {
    e.preventDefault();

    // const filteredServices = getOptions.filter(service => service.category === "Something’s Wrong");
    const filteredServices = getOptions.filter(
      (service) => service.category === "Something's Wrong"
    );

    const modal = (
      <ServicesModal title="Something's Wrong" services={filteredServices} />
    );
    dispatch(setModalContents(modal));
  };

  const handleRecommendedToggleClicked = (e) => {
    e.preventDefault();
    setRecommendedServicesOpen(!recommendedServicesOpen);
  };

  const handleCommonToggleClicked = (e) => {
    e.preventDefault();
    setCommonServicesOpen(!commonServicesOpen);
  };

  const handleSomethingWrongToggleClicked = (e) => {
    e.preventDefault();
    setSomethingWrongServicesOpen(!somethingWrongServicesOpen);
  };

  const handleRecommendedServiceChecked = (service) => {
    const newSelectedServices = selectedRecommendedServices.includes(service)
      ? selectedRecommendedServices.filter((item) => item !== service)
      : [...selectedRecommendedServices, service];

    setSelectedRecommendedServices(newSelectedServices);
    dispatch(setRecommendedServices(newSelectedServices));
  };

  // Render fallback for no services
  if (!services) {
    return <></>;
  }

  // Render
  return (
    <div className="landing" data-testid="landing-component">
      <h1 className="heading">
        How can our{" "}
        <a
          href={`https://www.cbac.com/${urlSlug}/`}
          className="landing-store"
          onClick={(e) =>
            handleRedirectClick(e, `https://www.cbac.com/${urlSlug}`)
          }
        >
          {franchiseName}
        </a>{" "}
        store help your <br />
        {`${vehicleInfo.year} ${vehicleInfo.make} ${vehicleInfo.model}?`}
      </h1>
      <p className="landing-instructions">
        Choose one or more of the services or symptoms below to begin booking
        your appointment.
      </p>
      {/* <div className="heading select-container"><span className="landing-i-need">I'd like help with</span> <span className="select-dropdown"><Dropdown upward={false} placeholder='Select your service(s)*' fluid multiple selection options={getOptions} onChange={handleChange} value={selectedServices} /></span> at the <a href="https://www.cbac.com/locations/" className="landing-store">{franchiseeName}</a> store.</div> */}

      {/* Recommended Services */}
      {isAuthenticated &&
      dgbVehicleId &&
      dgbCustomerData.garage?.length &&
      vehicleInfo &&
      !isVehicleNew ? (
        <section
          className="landing-services-section"
          data-testid="services-section"
        >
          <div className="landing-services-section-top">
            <h2>Previously Recommended Services</h2>
            <button
              className="collapse-button"
              onClick={handleRecommendedToggleClicked}
            >
              {getToggleIconRecommended}
            </button>
          </div>
          {recommendedServicesOpen && (
            <div
              // className="landing-services-section-grid"
              className="recommended-services"
              data-testid="recommended-services-grid"
            >
              {getRecommendedServices ?? (
                <div>
                  <p className="landing-instructions">
                    No recommended services available at this time
                  </p>
                </div>
              )}
            </div>
          )}
        </section>
      ) : null}

      {/* Common Services */}
      <div className={"services"}>{services.map((item) => item.name)}</div>

      {commonServices.length > 0 && (
        <section
          className="landing-services-section"
          data-testid="services-section"
        >
          <div className="landing-services-section-top">
            <h2>Common Services</h2>
            <button className="info-button" onClick={handleCommonInfoClicked}>
              <IconInfo />
            </button>
            <button
              className="collapse-button"
              onClick={handleCommonToggleClicked}
            >
              {getToggleIconCommon}
            </button>
          </div>
          {commonServicesOpen && (
            <div
              className="landing-services-section-grid"
              data-testid="services-grid"
            >
              {commonServices}
            </div>
          )}
        </section>
      )}

      {somethingWrongServices.length > 0 && (
        <section className="landing-services-section">
          <div className="landing-services-section-top">
            <h2>Something's Wrong</h2>
            <button
              className="info-button"
              onClick={handleSomethingWrongInfoClicked}
            >
              <IconInfo />
            </button>
            <button
              className="collapse-button"
              onClick={handleSomethingWrongToggleClicked}
            >
              {getToggleIcon}
            </button>
          </div>
          {somethingWrongServicesOpen && (
            <div className="landing-services-section-grid">
              {somethingWrongServices}
            </div>
          )}
        </section>
      )}

      {/* Other Field */}
      <div className="landing-other">
        <div className="landing-other-container">
          <p className="landing-instructions other-field">
            We would love to know more...
          </p>
          <textarea
            className={`landing-other-field${
              otherError ? " landing-other-error" : ""
            }`}
            maxLength="250"
            placeholder="Please provide any information about your vehicle concerns that you think would be helpful."
            value={otherText}
            onChange={handleAdditionalDetails}
          />
          <div className={"landing-other-instructions"}>
            {
              <p className="landing-other-error-text">
                {`${
                  otherError
                    ? "You’ve selected ‘Other’, please add a comment before continuing"
                    : " "
                } `}
              </p>
            }
            <p className="landing-instructions other-field character-count">
              {`${otherText.length}/250`}
            </p>
          </div>
        </div>
      </div>

      <BottomNav
        enableContinue={
          (selectedServices.length || recommendedServices.length) && !otherError
        }
        continueLabel="Continue"
        handleCancel={handleCancel}
        handleContinue={handleContinue}
        cancelLabel={"Previous"}
      />
    </div>
  );
}
