import "./LoadingScreen.css";
import LogoTop from "../assets/d-logo-half-l.png";

export default function LoadingScreen() {
  return (
    <div className="loader-container">
      <h1 className="loading-text">
        Logging you in
        {/* <span className="dots"></span> */}
      </h1>
      <img
        className="loading-logo"
        alt="Christian Brothers Automotive"
        title="Christian Brothers Automotive"
        src={LogoTop}
      />
    </div>
  );
}
