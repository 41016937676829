import "./ErrorScreen.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setRedirectError } from "../store/scheduler";

export default function ErrorScreen() {
  const dispatch = useDispatch();

  //? Should we clear this on load to allow someone to go back?
  useEffect(() => {
    dispatch(setRedirectError(false));
  });

  return (
    <div className="error-screen">
      <h1 className="heading">
        It's not a spark plug, but something isn't firing.
      </h1>
      <p>
        Please try again later or reach out directly to your store. We're sorry
        for any inconvenience.
      </p>
    </div>
  );
}
