import { useEffect, useState } from "react";
import {
  Route,
  useLocation,
  useNavigate,
  Routes,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDgbCustomer,
  getFranchiseByName,
  setCoupons,
  setDgbId,
  setDgbReferrer,
  setDgbVehicleId,
  setMarketingCookies,
  setCarePlusReferrer,
} from "./store/scheduler";

// Analytics
import TagManager from "react-gtm-module";

import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import FullscreenModal from "./components/FullscreenModal.jsx";

import LandingScreen from "./views/LandingScreen.jsx";
import DateTime from "./views/DateTime.jsx";
import NoLocationScreen from "./views/NoLocationScreen.jsx";
import VehicleScreen from "./views/VehicleScreen.jsx";
import PersonalInfoScreen from "./views/PersonalInfoScreen";
import ReviewScreen from "./views/ReviewScreen.jsx";
import CancellationScreen from "./views/CancellationScreen.jsx";
import ConfirmationScreen from "./views/ConfirmationScreen";
import ErrorScreen from "./views/ErrorScreen.jsx";
import DropoffScreen from "./views/DropoffScreen";
import ScrollToTop from "./components/ScrollToTop";
import ComingSoonScreen from "./views/ComingSoonScreen";
import {
  MsalProvider,
  useAccount,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { Cookies } from "react-cookie";
import VehicleSelectionScreen from "./views/VehicleSelectionScreen";
import { loginRequest, silentRequest } from "./authConfig";
import { msalInstance } from "./index";
import { InteractionStatus } from "@azure/msal-browser";
import LoadingScreen from "./views/LoadingScreen";

const tagManagerArgsCba = {
  gtmId: "GTM-KMF342W",
};

const tagManagerArgsScorp = {
  gtmId: "GTM-NL2MVM4",
};

// Init Scorpion + CBA containers
TagManager.initialize(tagManagerArgsCba);
TagManager.initialize(tagManagerArgsScorp);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const LocationDisplay = () => {
  const location = useLocation();

  return <div data-testid="location-display">{location.pathname}</div>;
};

const MainContent = () => {
  // React Router
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    franchise,
    redirectError,
    modalContents,
    mktCookies,
    coupons,
    dgbId,
    dgbVehicleId,
    dgbReferrer,
  } = useSelector((state) => state.scheduler);

  // const franchiseName = location && location.pathname.split('/')[1];
  const franchiseName = location.pathname.split("/")[1];
  const [searchParams, setSearchParams] = useSearchParams();

  const { accounts, instance, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();
  const activeAccount = instance.getActiveAccount();

  // Effect for writing our scorpion ID
  // useEffect(()=>{
  //   if(!query) return;
  //
  //   const scorpionId = query.get('sid');
  //
  //   if(scorpionId){
  //     dispatch(setPersonalInfo({scorpionSessionId: scorpionId}));
  //   }
  //
  // }, [])

  // useEffect(() => {
  //   if (dgbReferrer && !isAuthenticated) {
  //     navigate(
  //       { pathname: "/loading", search: location.search },
  //       { state: franchiseName }
  //     );
  //   }
  // }, []);

  // Initial load - check for location information in cookie or route
  useEffect(() => {
    if (franchiseName) {
      // Hack to handle no-location base route
      if (
        franchiseName === "no-location" ||
        franchiseName === "cancel" ||
        franchiseName === "error" ||
        franchiseName === "redirect" ||
        franchiseName === "loading"
      ) {
        return;
      }

      if (franchise.franchiseName) return;

      // API call using franchise name
      dispatch(getFranchiseByName(franchiseName));
      return;
    }

    // Fallback to no-location screen
    navigate({ pathname: "/no-location", search: location.search });
  }, [franchise]);

  useEffect(() => {
    if (
      franchiseName === "no-location" ||
      franchiseName === "cancel" ||
      franchiseName === "error" ||
      franchiseName === "redirect" ||
      franchiseName === "loading"
    ) {
      return;
    }

    // Add getDgbObject call here
    if (isAuthenticated && dgbId) {
      dispatch(getDgbCustomer(dgbId));
    }

    if (!dgbVehicleId && sessionStorage.getItem("dgb.vehicleId")) {
      dispatch(setDgbVehicleId(sessionStorage.getItem("dgb.vehicleId")));
    }
  }, [isAuthenticated, dgbId]);

  useEffect(async () => {
    if (dgbReferrer) {
      const silentTokenRequest = {
        scopes: ["openid", "profile"],
        loginHint: dgbReferrer,
      };

      console.log(silentTokenRequest);
      if (inProgress === InteractionStatus.None && !isAuthenticated) {
        await instance
          .loginRedirect(loginRequest)
          .then((response) => dispatch(setDgbId(response.idTokenClaims.dgbId)))
          .catch((error) => console.log(error));
      }
    }
    if (searchParams.has("vehicleId")) {
      const vehicleId = searchParams.get("vehicleId");
      sessionStorage.setItem("dgb.vehicleId", vehicleId);
      dispatch(setDgbVehicleId(vehicleId));
    }

    if (isAuthenticated) {
      searchParams.delete("dgb");
      setSearchParams(searchParams);
    }
  }, [instance, inProgress]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setDgbId(activeAccount.idTokenClaims.dgbId));
    }
  }, [isAuthenticated]);

  // Redirect to our error if we have one
  useEffect(() => {
    if (redirectError) {
      navigate("/error");
    }
  }, [redirectError]);

  // Effect to hide scrolling when modal open
  useEffect(() => {
    if (!document || !document.body) return;

    document.body.style.overflow = modalContents ? "hidden" : "auto";
  }, [modalContents]);

  //* Google Analytics Page Views
  const trackPageView = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page: {
          path: window.location.pathname,
        },
      },
    });
  };

  // Effect for Google Analytics
  useEffect(() => {
    // trackPageView(); // To track the first pageview upon load
    // history.listen(trackPageView);
    // navigate.listen(trackPageView);
  }, [location]);

  useEffect(() => {
    if (mktCookies.length < 1) {
      const cookieData = document.cookie
        .split("; ")
        .filter(
          (row) =>
            row.startsWith("_SAA=") ||
            row.startsWith("__origin_landing=") ||
            row.startsWith("__origin_referrer=")
        )
        .map((c) => {
          const item = c.split("=");
          return {
            key: item[0].split("_").slice(-1),
            value: item[1] ?? "",
          };
        })
        .reduce((obj, cookie) => ({ ...obj, [cookie.key]: cookie.value }), {});

      const mktCookies = {
        sa: cookieData["SAA"] ?? "",
        landing: cookieData.landing ?? "",
        referrer: cookieData.referrer ?? "",
      };
      dispatch(setMarketingCookies(mktCookies));
    }
  }, [mktCookies]);

  // Check for FB Campaigns
  useEffect(() => {
    if (searchParams) {
      const utmSource = (searchParams.get("utm_source") || "")
          .replace(/[_-]/g, ' ')
          .replace(/\b\w/g, char => char.toUpperCase());
      const cbaCoupon = searchParams.get("cba_coupon");
      if (utmSource?.length > 0 && cbaCoupon?.length > 0) {
        dispatch(setCoupons([...coupons, `${utmSource} Coupon: ${cbaCoupon}`]));
      } else if (cbaCoupon?.length > 0) {
        dispatch(setCoupons([...coupons, `CBAC Coupon: ${cbaCoupon}`]));
      }
      if (searchParams.has("dgb")) {
        dispatch(setDgbReferrer(`+${searchParams.get("dgb").trim()}`));
      }
      if (
        searchParams.has("careplus") &&
        searchParams.get("careplus") === "true"
      ) {
        dispatch(setCarePlusReferrer("careplus"));
      }
    }
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <FullscreenModal />
        <ScrollToTop />
        <Routes>
          <Route index path="/" element={<VehicleSelectionScreen />} />

          {/* Loading screen */}
          <Route path="/loading" element={<LoadingScreen />} />

          {/* Location fallback screen */}
          <Route path="/no-location" element={<NoLocationScreen />} />

          {/* Error fallback screen */}
          <Route path="/error" element={<ErrorScreen />} />

          {/* Redirect for cancellation */}
          <Route
            path="/cancel/:cancellationId"
            element={<CancellationScreen />}
          />

          {/* Vehicle Screen */}
          <Route
            path="/:location"
            element={
              franchise.locationId ? (
                franchise.schedulerLive ? (
                  <VehicleSelectionScreen />
                ) : (
                  <ComingSoonScreen />
                )
              ) : null
            }
          />

          {/* Landing Screen or Coming Soon screen */}
          <Route path="/:location/services" element={<LandingScreen />} />

          {/* Date-Time Picker Screen */}
          <Route path="/:location/dropoff" element={<DropoffScreen />} />

          {/* Date-Time Picker Screen */}
          <Route path="/:location/date-time" element={<DateTime />} />

          {/* Vehicle Screen */}
          {/* <Route path="/:location/vehicle-info" element={<VehicleScreen />} /> */}

          {/* Personal Information Form */}
          <Route
            path="/:location/personal-info"
            element={<PersonalInfoScreen />}
          />

          <Route path="/:location/review" element={<ReviewScreen />} />

          <Route
            path="/:location/confirmation"
            element={<ConfirmationScreen />}
          />

          {/*<Route path="/" element={<Navigate to={franchiseName ? '/' + franchiseName : '/'} replace/>} />*/}

          {/*<Route path="*" element={<LandingScreen />} />*/}

          {/* Fallback if user directly enters scheduler */}
          {/*<Route path="/" element={<LandingScreen />} />*/}
          {/*<Route path="/" render={() => <Redirect to="/:location/" />} />*/}
        </Routes>

        {/*<LocationDisplay />*/}
      </main>
      <Footer />
    </div>
  );
};

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <MainContent />
    </MsalProvider>
  );
};

export default App;
