import "./ServicesModal.css";
import { serviceIcons } from "../../assets/icons/services/index";
import { ReactComponent as IconStayAndWait } from "../../assets/icons/stay-and-wait.svg";
import { ReactComponent as IconClose } from "../../assets/icons/icons-close2.svg";
import { useDispatch } from "react-redux";
import { setModalContents } from "../../store/scheduler";

export default function ServicesModal({ title = "Common Services", services }) {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setModalContents(""));
  };

  const handleClose = (e) => {
    e.preventDefault();
    closeModal();
  };

  const getServices = () => {
    return services.map((service, index) => {
      // Returns a list of common services from our API
      const IconElement = service.icon ? serviceIcons[service.icon] : null;

      return (
        <div className="services-row" key={`${service.serviceId}_${index}`}>
          <div className="services-modal-icon">
            <IconElement />
          </div>
          <div className="services-row-info services-row-title">
            {service.text}
          </div>
          <div className="service-icon-container-mobile">
            <div className="services-modal-icon-mobile">
              <IconElement />
            </div>
            <div className="services-row-info services-row-title-mobile">
              {service.text}
            </div>
          </div>
          <div className="services-row-info services-row-description">
            {service.description}
          </div>
          <div className="services-row-info services-row-container">
            <div className="services-row-info services-row-saw-icon">
              {service.stayAndWait && <IconStayAndWait />}
            </div>
            <div className="services-row-info services-row-saw">
              {service.stayAndWait && "Stay & Wait Available"}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="services-modal">
      <button className="services-modal-close" onClick={handleClose}>
        <IconClose />
      </button>
      <h1>{title}</h1>
      <div className="services-modal-container">{getServices()}</div>
    </div>
  );
}
