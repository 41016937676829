import BottomNav from "../BottomNav.jsx";

export default function TimeModal({ handleCancel, handleContinue }) {
  return (
    <>
      <h1 className="heading time-modal-head">
        You've made changes to the services, please select a time.
      </h1>
      <p>The changes you've made require you to select a time.</p>
      <BottomNav
        continueLabel="Select Time"
        cancelLabel="No"
        handleCancel={handleCancel}
        handleContinue={handleContinue}
        enableContinue={true}
        hideCancel
      />
    </>
  );
}
