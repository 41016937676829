import { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Divider, Dropdown } from "semantic-ui-react";
import BottomNav from "../components/BottomNav.jsx";
import {
  clearVehicleModels,
  clearVehicleMakes,
  getVehicleMakesByYear,
  getVehicleModelsByYearAndMake,
  getVehicleYears,
  setVehicleInfo,
  setCurrentStep,
  setDgbVehicleId,
  setIsVehicleNew,
} from "../store/scheduler";
import "./VehicleSelectionScreen.css";
import { useIsAuthenticated } from "@azure/msal-react";
import LoadingScreen from "./LoadingScreen";

export default function VehicleSelectionScreen() {
  const dispatch = useDispatch();
  const {
    vehicleYears,
    vehicleMakes,
    vehicleModels,
    vehicleInfo,
    franchise,
    dgbCustomerData,
    dgbVehicleId,
  } = useSelector((state) => state.scheduler);
  const urlSlug = useSelector((state) => state.scheduler.franchise.urlSlug);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAddVehicle, setIsAddVehicle] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const dgbReferrer = useSelector((state) => state.scheduler.dgbReferrer);

  const colors = [
    "Beige",
    "Black",
    "Blue",
    "Brown",
    "Gold",
    "Grey",
    "Green",
    "Maroon",
    "Orange",
    "Purple",
    "Red",
    "Silver",
    "White",
    "Yellow",
    "Other",
  ];

  const getColors = () => {
    return colors.map((color, index) => {
      return {
        key: color + "index" + index,
        text: color,
        value: color,
      };
    });
  };

  // TODO: Adjust garageId for each vehicle on data load
  // const getInitialVehicle = useMemo(() => {

  const selectVehicle = (value) => {
    // console.log("selectVehicle value", value);
    if (dgbCustomerData.garage?.length) {
      const vehicle = dgbCustomerData.garage?.find(
        // (vehicle, index) => vehicle.garageId === value
        (item, index) => item.garageId === value
      );

      // console.log({
      //   vehicle: vehicle,
      //   year: vehicle.year ?? "",
      //   make: vehicle.make ?? "",
      //   model: vehicle.model ?? "",
      //   color: vehicle.color ?? "",
      // });
      dispatch(
        setVehicleInfo({
          year: vehicle.year ?? "",
          make: vehicle.make ?? "",
          model: vehicle.model ?? "",
          color: vehicle.color ?? "",
        })
      );
      dispatch(setDgbVehicleId(vehicle.garageId));

      // dispatch(setVehicleInfo({ year: value.year, make: "", model: "" }));
      // dispatch(getVehicleModelsByYearAndMake());

      // dispatch(clearVehicleModels());

      // Clear our color if we have it
      // if (vehicleInfo.color) {
      //   dispatch(setVehicleInfo({ color: "" }));
      // }
    }
  };

  const getInitialVehicle = useMemo(() => {
    if (dgbCustomerData && dgbVehicleId) {
      console.log(dgbVehicleId);
      selectVehicle(dgbVehicleId);
      return dgbVehicleId;
    }
  }, [dgbCustomerData, dgbVehicleId]);

  const getUserVehicles = useMemo(() => {
    if (dgbCustomerData.garage?.length) {
      return dgbCustomerData.garage.map((vehicle, index) => {
        return {
          key: index,
          text: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
          value: vehicle.garageId.toString(),
          // value: index,
        };
      });
    }

    return [];
  }, [dgbCustomerData]);

  const getYears = useMemo(() => {
    if (vehicleYears.length) {
      return vehicleYears.map((year, index) => {
        return {
          key: year + "index" + index,
          text: year,
          value: year,
        };
      });
    }

    return [];
  }, [vehicleYears]);

  const getMakes = useMemo(() => {
    // console.log("vehicleMakes", vehicleMakes);

    if (vehicleMakes.length) {
      return vehicleMakes.map((make, index) => {
        return {
          key: make + "index" + index,
          text: make,
          value: make,
        };
      });
    }

    return [];
  }, [vehicleMakes]);

  // console.log("makes", getMakes);

  const getModels = useMemo(() => {
    if (vehicleModels.length) {
      return vehicleModels.map((model, index) => {
        return {
          key: model + "index" + index,
          text: model,
          value: model,
        };
      });
    }

    return [];
  }, [vehicleModels]);

  // const selectVehicle = (e, { value }) => {

  const selectYear = (e, { value }) => {
    e.preventDefault();
    // Wipe makes and models
    // dispatch(clearVehicleMakes());
    // dispatch(clearVehicleModels());
    // Update year in redux
    //* We clear the make/model selections here since we picked new year
    dispatch(setVehicleInfo({ year: value, make: "", model: "" }));
    dispatch(getVehicleMakesByYear(value));

    // // Wipe makes and models
    dispatch(clearVehicleMakes());
    dispatch(clearVehicleModels());

    // Clear our color if we have it
    if (vehicleInfo.color) {
      dispatch(setVehicleInfo({ color: "" }));
    }
  };

  const selectMake = (e, { value }) => {
    e.preventDefault();

    //* We clear model here since we picked a new make
    dispatch(setVehicleInfo({ make: value, model: "" }));
    dispatch(getVehicleModelsByYearAndMake());

    dispatch(clearVehicleModels());

    // Clear our color if we have it
    if (vehicleInfo.color) {
      dispatch(setVehicleInfo({ color: "" }));
    }
  };

  const selectModel = (e, { value }) => {
    e.preventDefault();

    dispatch(setVehicleInfo({ model: value }));

    // Clear our color if we have it
    if (vehicleInfo.color) {
      dispatch(setVehicleInfo({ color: "" }));
    }
  };

  const selectColor = (e, { value }) => {
    e.preventDefault();

    dispatch(setVehicleInfo({ color: value }));
  };

  // const handleCancel = (e) => {
  //   e.preventDefault();
  //   // history.goBack();
  //   navigate(-1);
  // };

  const handleCancel = () => {
    // if (env !== "TEST") {
    window.location = `https://www.cbac.com/${urlSlug}/`;
    // }
  };

  const handleContinue = (e) => {
    e.preventDefault();
    // Navigate to personal information page
    // history.push(`/${franchise.urlSlug}/personal-info`);
    // navigate(`/${franchise.urlSlug}/personal-info`);
    // TODO: Change to landing page
    navigate(`/${franchise.urlSlug}/services`, {
      state: { referrer: location },
    });
  };

  // // Redirect if we are ever missing services
  // useEffect(() => {
  //   // if (!selectedServices.length && urlSlug) {
  //   if (!selectedServices.length && !recommendedServices.length && urlSlug) {
  //     // history.push(`/${urlSlug}/`);
  //     navigate(`/${urlSlug}/`);
  //   }
  // }, [urlSlug, selectedServices, recommendedServices, navigate]);

  // Component Did Mount
  useEffect(() => {
    // dispatch(getVehicleYears());
    dispatch(setCurrentStep(1));
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      setIsAddVehicle(false);
      dispatch(setIsVehicleNew(false));
    } else {
      setIsAddVehicle(true);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAddVehicle) {
      dispatch(setVehicleInfo({ year: "", make: "", model: "", color: "" }));
      dispatch(getVehicleYears());
      dispatch(setIsVehicleNew(isAddVehicle));
      // TODO: Remove recommended services if adding a vehicle
    }
    if (
      isAuthenticated &&
      !isAddVehicle &&
      dgbCustomerData.garage?.length &&
      dgbVehicleId
    ) {
      selectVehicle(dgbVehicleId);
      dispatch(setIsVehicleNew(false));
    }
  }, [isAddVehicle]);

  return dgbReferrer && !isAuthenticated ? (
    <LoadingScreen />
  ) : (
    <div className="vehicle-screen">
      <h1 className="heading">Welcome! What type of vehicle do you have?</h1>
      {!isAuthenticated ? (
        <p className={"vehicle-disclaimer"}>
          If your vehicle is not found, please call to schedule service.
        </p>
      ) : null}
      <div className="vehicle-selection-screen-container">
        {/* <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}> */}
        <div style={{ gap: "6px" }}>
          {/* <div className="heading i-have">I have a</div> */}
          {/* <p className={"vehicle-disclaimer"}> */}
          {isAuthenticated && !isAddVehicle ? (
            <>
              {/* <h4 style={{ fontWeight: 500, margin: 0 }}> */}
              <h4 style={{ fontWeight: 500 }}>Select your vehicle</h4>
              <Dropdown
                className={"vehicle-selection-dropdown"}
                id="vehicle-select"
                aria-label="vehicle"
                placeholder={"Select vehicle"}
                search
                selection
                defaultValue={getInitialVehicle}
                options={getUserVehicles}
                onChange={(event, { value }) => {
                  event.preventDefault();
                  selectVehicle(value);
                }}
                upward={false}
              />
              <h3>OR</h3>
            </>
          ) : null}

          {isAuthenticated ? (
            <button
              className={`handle-vehicle-button`}
              onClick={() => setIsAddVehicle(!isAddVehicle)}
            >
              {(!isAddVehicle ? "Add" : "Select") + ` a vehicle`}
            </button>
          ) : null}

          {isAddVehicle ? (
            <>
              {isAuthenticated ? (
                <>
                  <h3>OR</h3>
                  <h4 style={{ fontWeight: 500, marginBottom: "6px" }}>
                    {`Enter your vehicle details`}
                  </h4>
                  <br />
                  <h5 style={{ fontWeight: 500, marginTop: "-12px" }}>
                    If your vehicle is not found, please call to schedule
                    service.
                  </h5>
                </>
              ) : null}
              {/* <div style={{ display: "flex" }}> */}
              {/* <div className="heading i-have">I have a</div> */}
              <Dropdown
                id="year-select"
                aria-label="year"
                search
                placeholder="Year"
                selection
                options={getYears}
                value={vehicleInfo.year}
                onChange={selectYear}
                upward={false}
              />
              <Dropdown
                id="make-select"
                aria-label="make"
                search
                placeholder="Make"
                selection
                options={getMakes}
                disabled={!vehicleMakes.length}
                value={vehicleInfo.make}
                onChange={selectMake}
                upward={false}
              />
              <Dropdown
                id="model-select"
                aria-label="model"
                search
                placeholder="Model"
                selection
                options={getModels}
                disabled={!vehicleModels.length}
                value={vehicleInfo.model}
                onChange={selectModel}
                upward={false}
              />
              <Dropdown
                id="color-select"
                aria-label="color"
                search
                placeholder="Color (Optional)"
                selection
                options={getColors()}
                disabled={!vehicleInfo.model}
                onChange={selectColor}
                value={vehicleInfo.color}
                upward={false}
              />
            </>
          ) : null}
        </div>
      </div>
      <BottomNav
        enableContinue={vehicleInfo.model}
        handleCancel={handleCancel}
        handleContinue={handleContinue}
        // cancelLabel="Previous"
        cancelLabel="Cancel"
      />
    </div>
  );
}
