import { useSelector } from "react-redux";
import "./FullscreenModal.css";

export default function FullscreenModal() {
  const { modalContents } = useSelector((state) => state.scheduler);

  return (
    <>
      {modalContents && (
        <div className="fullscreen-modal">
          <div className="modal-container">{modalContents}</div>
        </div>
      )}
    </>
  );
}
