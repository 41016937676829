import "./ServiceIcon.css";
import { serviceIcons } from "../assets/icons/services/index";
import { ReactComponent as IconSelected } from "../assets/icons/selected-check.svg";

export default function ServiceIcon({ selected, handleClick, service }) {
  const IconElement = service ? serviceIcons[service.icon] : null;

  // Handler for our click
  const handleButtonClicked = (e) => {
    e.preventDefault();
    e.currentTarget.blur();

    // Validate that we have a click function
    if (handleClick && typeof handleClick === "function") {
      // If we are selected, we want to remove/deselect the service
      handleClick(service.value, selected);
    }
  };

  // Break if no service
  if (!service) {
    return <></>;
  }

  return (
    <button
      className="service-icon"
      role="switch"
      onClick={handleButtonClicked}
      aria-checked={selected}
      aria-label={service.text}
    >
      <div>
        {selected && <IconSelected className="service-svg-icon" />}
        {!selected && IconElement && (
          <IconElement className="service-svg-icon" />
        )}
      </div>
      <span className="service-text">{service.text}</span>
    </button>
  );
}
