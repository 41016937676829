import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import { combineReducers } from 'redux'

import scheduler from "./scheduler";

export const reducer = combineReducers({
  scheduler,
});

const store = configureStore({
  reducer: {
    scheduler: scheduler,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
