// Buttons for forward/backward navigation at the bottom of each screen
import "./BottomNav.css";

export default function BottomNav({
  enableContinue,
  handleCancel,
  handleContinue,
  cancelLabel = "Cancel",
  cancelTabIndex,
  continueLabel = "Continue",
  continueTabIndex,
  fakeDisabled = false,
  hideCancel = false,
}) {
  const handleCancelClicked = (e) => {
    e.preventDefault();
    if (handleCancel) {
      handleCancel(e);
    }
  };

  const handleContinueClicked = (e) => {
    e.preventDefault();
    // if(!enableContinue) return;

    if (handleContinue && enableContinue);
    {
      handleContinue(e);
    }
  };

  return (
    <div className="bottom-nav">
      {!hideCancel && (
        <button
          className="cancel-button"
          onClick={handleCancelClicked}
          tabIndex={cancelTabIndex}
        >
          {cancelLabel}
        </button>
      )}
      <button
        className={`continue-button${fakeDisabled ? " fake-disabled" : ""}`}
        onClick={handleContinueClicked}
        disabled={!enableContinue && !fakeDisabled}
        tabIndex={continueTabIndex}
        aria-label={continueLabel}
      >
        {continueLabel}
      </button>
    </div>
  );
}
