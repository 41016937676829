import { useIsAuthenticated, useMsal } from "@azure/msal-react";
// import { SignInButton } from "./SignInButton";
// import { SignOutButton } from "./SignOutButton";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";
import "./SignInSignOutButton.css";
import { setDgbId } from "../../store/scheduler";
import { useDispatch } from "react-redux";

const SignInSignOutButton = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();

  const handleLogin = (loginType) => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      console.log("handleLogin");
      if (loginType === "popup") {
        instance.loginPopup({
          ...loginRequest,
          redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI, // e.g. /redirect
        });
      } else if (loginType === "redirect") {
        instance
          .loginRedirect(loginRequest)
          .then((response) => dispatch(setDgbId(response.idTokenClaims.dgbId)))
          .catch((error) => console.log(error));
      }
    }
  };

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup();
    } else if (logoutType === "redirect") {
      instance.logoutRedirect();
    }
  };

  if (isAuthenticated) {
    // return <Button onClick={() => handleLogout("redirect")}>Logout</Button>;
    return (
      <button
        className="sign-in-button"
        onClick={() => handleLogout("redirect")}
      >
        Logout
      </button>
    );
    // return <button onClick={() => handleLogout("popup")}>Logout</button>;
  } else if (
    inProgress !== InteractionStatus.Startup &&
    inProgress !== InteractionStatus.HandleRedirect
  ) {
    return null;
    // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
    // return (
    //   <button
    //     className="sign-in-button"
    //     onClick={() => handleLogin("redirect")}
    //   >
    //     Login
    //   </button>
    // );
    // return <button onClick={() => handleLogin("popup")}>Login</button>;
  } else {
    return null;
  }
};

export default SignInSignOutButton;
